import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const WEALTH_SERIES_NEWS = "WS/NEWS";
export const WEALTH_SERIES_NEWS_SUCCESS = "WS/NEWS/SUCCESS";
export const WEALTH_SERIES_NEWS_FAILURE = "WS/NEWS/FAILURE";

// Action Creators
export const wealthNewsLoad = (ticker) => ({
  type: WEALTH_SERIES_NEWS,
  ticker,
});
export const wealthNewsLoadSuccess = (data, loading = false) => ({
  type: WEALTH_SERIES_NEWS_SUCCESS,
  data,
  loading,
});
export const wealthNewsLoadFailure = (error, loading = false) => ({
  type: WEALTH_SERIES_NEWS_FAILURE,
  error,
  loading,
});

// Sagas
function* fetchWealthNews(action) {
  try {
    yield put(wealthNewsLoadSuccess([], true));

    const { ticker } = action;
    const response = yield axios.get(`${apiURL}/wealth-series/news/${ticker}`, {
      withCredentials: true,
    });
    if (!response.data) {
      yield put(wealthNewsLoadFailure("no data found"));
      return;
    }
    yield put(wealthNewsLoadSuccess(response.data?.news ?? [], false));
  } catch (error) {
    yield put(wealthNewsLoadFailure(error, false));
  }
}

function* listenFetchWealthNews() {
  yield takeEvery(WEALTH_SERIES_NEWS, fetchWealthNews);
}

// Root Saga
export function* saga() {
  yield all([fork(listenFetchWealthNews)]);
}

const INIT_STATE = {
  news: [],
  loading: true,
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case WEALTH_SERIES_NEWS_SUCCESS:
      return {
        ...state,
        news: action.data,
        loading: action.loading,
      };
    case WEALTH_SERIES_NEWS_FAILURE:
      return {
        ...state,
        news: [],
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default reducer;
