import React, { useState } from "react";
import { connect } from "react-redux";
import VerifiedIcon from "@mui/icons-material/Verified";
import UserAvatar from "../../../../assets/images/wealth-series/overview/mike.png";
import "./WSLiveStream.scss";
import PreviousVideos from "../../../BasicsTraining/PreviousVideos";
import { defaultVideoState } from "../../../../helpers/defaultVideoState";
import SocialCareLightImg from "../../../../assets/images/social-care.gif";
import SocialCareDarkImg from "../../../../assets/images/social-care-dark.gif";
import { useParams } from "react-router-dom";

const WSLiveStream = ({
  subTitle,
  scheduleDate,
  description,
  eventID,
  themeMode,
  goBookMentorship,
}) => {
  const [currentVideo, setCurrentVideo] = useState(defaultVideoState);
  const [tab, setTab] = useState("1");
  const { series } = useParams();
  return (
    <div className="ws-live-stream">
      {currentVideo.live ? (
        <div className="ws-live-stream__header">
          <div className="ws-live-stream__left">
            <iframe
              className="ws-live-stream__video"
              src={`https://vimeo.com/event/${eventID}/embed`}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title={"LiveStream"}
              frameBorder="0"
            ></iframe>
          </div>

          <div className="ws-live-stream__right">
            <iframe
              src={`https://vimeo.com/event/${eventID}/chat`}
              width="100%"
              height="100%"
              frameBorder="0"
              title="livestream-chat"
            />
          </div>
        </div>
      ) : (
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: currentVideo.url,
            }}
          />
          <div style={{ position: "relative", top: "5px", left: "29px" }}>
            <div className="row">
              <span
                className="col-12"
                style={{ color: "#A2A3A5", whiteSpace: "nowrap" }}
              >
                {currentVideo.duration} | {currentVideo.date} |{" "}
                {tab === "1" ? "Group Training" : ""}
              </span>
            </div>
            <div className="row">
              <span className="col-12" style={{ paddingTop: "10px" }}>
                {currentVideo.description}
              </span>
            </div>
          </div>
          <br />
          <div
            style={{
              width: "99%",
              border: "1px solid #F1F1F1",
              marginLeft: "24px",
            }}
          ></div>
          <br />
        </div>
      )}
      <div className="ws-live-stream__body">
        <div className="ws-live-stream__left">
          <div className="ws-live-stream__content">
            <div className="ws-live-stream-author">
              <img
                src={UserAvatar}
                alt="Mike Andrews"
                className="ws-live-stream-author-avatar"
              />
              <div className="ws-live-stream-author-info">
                <div className="ws-live-stream-author-name">
                  Mike Andrews <VerifiedIcon />
                </div>
                <div className="ws-live-stream-author-title">
                  TradeAlgo Professional Analyst
                </div>
              </div>
            </div>

            <div className="ws-live-stream__content-title">{subTitle}</div>
            <div className="ws-live-stream__content-scheduleDate">
              {scheduleDate}
            </div>
            <div className="ws-live-stream__content-description">
              {description}
            </div>
          </div>
        </div>

        <div className="ws-live-stream__right ws-live-stream-cta-container">
          <div className="ws-live-stream-cta" onClick={goBookMentorship}>
            <img
              src={
                themeMode === "light" ? SocialCareLightImg : SocialCareDarkImg
              }
              alt="social-care"
              className="ws-live-stream-cta-img"
            />
            <div className="ws-live-stream-cta-texts">
              <div className="ws-live-stream-cta-text">Book your 1-on-1</div>
              <div className="ws-live-stream-cta-text">Mentorship Session</div>
            </div>
          </div>
        </div>
      </div>
      <PreviousVideos
        tab={tab}
        setTab={setTab}
        basics={false}
        titleFilter={null}
        customPageSize={9}
        currentVideo={currentVideo}
        setCurrentVideo={setCurrentVideo}
        videoKey={`wealth-series-${series}`}
      />
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(WSLiveStream);
