import "./WealthSeriesPortfolioNews.scss";
import { connect } from "react-redux";
import moment from "moment";
import BeautifulSkeleton from "../../../common/Skeletons/BeautifulSkeleton";
import React, { useEffect, useMemo, useState } from "react";
import FallbackTickerIcon from "../../../common/FallbackTickerIcon/FallbackTickerIcon";
import LinkIcon from "@mui/icons-material/Link";
import { wealthNewsLoad } from "../../../../appRedux/ducks/wealthNews";
import { useAuth } from "../../../../contexts/Auth";

const WealthSeriesPortfolioNews = ({
  loading,
  news,
  portfolioTicker,
  fetchWealthNews,
}) => {
  const { isDemo } = useAuth();
  const [isBlurred, setIsBlurred] = useState(false);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setIsBlurred(searchParams.get("blurred") === "true" || isDemo);
  }, [location, isDemo]);
  const newsContent = useMemo(() => {
    if (loading) {
      return Array.from({ length: 10 }, () => {
        const randomWidth = Math.floor(Math.random() * (100 - 50 + 1)) + 50; // Random width between 50% and 100%
        const randomMarginTop = Math.floor(Math.random() * 10); // Random margin-top between 0 and 9
        return (
          <BeautifulSkeleton
            key={Math.random()} // Using Math.random() to ensure a unique key
            width={`${randomWidth}%`}
            height={20}
            style={{ marginTop: `${randomMarginTop}px` }}
          />
        );
      });
    }

    if (news?.length > 0) {
      return (
        <>
          {news.map((article, index) => (
            <div className="ws-portfolio-news-item" key={index}>
              <div className="ws-portfolio-news-date">
                {article.published_ts
                  ? moment(article.published_ts).format("MMM D, YYYY")
                  : ""}
              </div>
              <div
                className={`ws-portfolio-news-title ${isBlurred ? "blurred" : ""}`}
              >
                {article.llm_summary ? article.llm_summary : ""}
              </div>
              <div className="ws-portfolio-news-footer">
                {article.article_url && (
                  <a
                    href={article.article_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkIcon />
                    {article.source ? article.source : "Read more"}
                  </a>
                )}
              </div>
            </div>
          ))}
        </>
      );
    }

    return <div>No news available.</div>;
  }, [loading, news, portfolioTicker]);

  useEffect(() => {
    if (portfolioTicker) {
      fetchWealthNews(portfolioTicker);
    }
  }, [portfolioTicker]);

  return (
    <div className="ws-portfolio-news">
      <div
        className={`ws-portfolio-news__header ${isBlurred ? "blurred" : ""}`}
      >
        {loading ? (
          <BeautifulSkeleton variant="circular" width={32} height={32} />
        ) : (
          <FallbackTickerIcon
            src={`https://assets.tradealgo.com/stock-logos/${portfolioTicker}.png`}
            ticker={portfolioTicker}
            className="ws-portfolio-news-logo"
          />
        )}
        <div className="ws-portfolio-news__header-content">
          <div className="ws-portfolio-news-summarized">
            {portfolioTicker} AI Summarized News
          </div>
          {/* <div className="ws-portfolio-news-timestamp">
            {moment().format("dddd, MMM D, YYYY")}
          </div> */}
        </div>
      </div>

      <div className="ws-portfolio-news__content ws-scroll-element">
        {newsContent}
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  loading: state.wealthNews.loading,
  news: state.wealthNews.news,
  portfolioTicker: state.portfolioTicker.symbol,
});

const dispatchToProps = (dispatch) => ({
  fetchWealthNews: (symbol) => dispatch(wealthNewsLoad(symbol)),
});

export default connect(
  stateToProps,
  dispatchToProps,
)(WealthSeriesPortfolioNews);
