import React, { useEffect, useMemo, useState } from "react";
import "./WealthSeriesPortfolioInfo.scss";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AtlasLogo from "../../../../assets/images/wealth-series/atlas-logo.png";
import TitanLogo from "../../../../assets/images/wealth-series/titan-logo.png";
import EverestLogo from "../../../../assets/images/wealth-series/everest-logo.png";
import AtlasLogoDark from "../../../../assets/images/wealth-series/atlas-logo-dark.png";
import TitanLogoDark from "../../../../assets/images/wealth-series/titan-logo-dark.png";
import EverestLogoDark from "../../../../assets/images/wealth-series/everest-logo-dark.png";
import { useHistory, useParams } from "react-router-dom";
import {
  Backdrop,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Tooltip,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import LockIcon from "@mui/icons-material/Lock";
import { connect } from "react-redux";
import { portfolioLoad } from "../../../../appRedux/ducks/wealthPortfolio";
import { portfolioHashMap } from "../WealthSeriesPortfolioTickers/tickerData";
import BeautifulSkeleton from "../../../common/Skeletons/BeautifulSkeleton";
import {
  fetchSubscribedPortfoliosRequest,
  portfolioSubscriptionRequest,
} from "../../../../appRedux/ducks/portfolioSubscription";
import { useAuth } from "../../../../contexts/Auth";

const WealthSeriesPortfolioInfo = ({
  themeMode,
  fetchPortfolioData,
  wealthPortfolios,
  loadingPortfolio,
  fetchSubscribed,
  handleSubscription,
  loadingSub,
  subscribedPortfolios,
  isMobile = false,
  lastUpdated,
}) => {
  const { hasWealthSeriesAtlas, hasWealthSeriesTitan, hasWealthSeriesEverest } =
    useAuth();
  const { portfolio, series } = useParams();
  const [selectedPortfolio, setSelectedPortfolio] = useState(portfolio);
  const history = useHistory();
  useEffect(() => {
    fetchSubscribed();
  }, []);
  const findLabelByPortfolioId = (portfolioId, tierData) => {
    if (!portfolioId || !Array.isArray(tierData)) return "";

    for (const tier of tierData) {
      if (!tier.values) continue; // Guard against missing or undefined values

      const foundValue = tier.values.find(
        (value) => value.id === portfolioHashMap[portfolioId],
      );
      if (foundValue) {
        return foundValue.label;
      }
    }

    return "";
  };
  const portfolioDatabaseId = portfolioHashMap[selectedPortfolio];
  const isSubscribed = subscribedPortfolios.includes(portfolioDatabaseId);

  useEffect(() => {
    if (!wealthPortfolios[portfolio]) {
      fetchPortfolioData(portfolioDatabaseId);
    }
  }, [selectedPortfolio]);

  const tierData = useMemo(() => {
    return [
      {
        logo: themeMode === "light" ? AtlasLogo : AtlasLogoDark,
        name: "Atlas",
        hasAccess: hasWealthSeriesAtlas,
        values: [
          {
            name: "atlas_growth",
            label: "Accelerated Growth",
            percentage: 11.8,
            id: portfolioHashMap["atlas_growth"],
          },
          {
            name: "atlas_low-risk-volatility",
            label: "Secure Bluechip",
            percentage: 8.6,
            id: portfolioHashMap["atlas_low-risk-volatility"],
          },
          {
            name: "atlas_fundamental",
            label: "Undervalued Advantage",
            percentage: -2.3,
            id: portfolioHashMap["atlas_fundamental"],
          },
        ],
      },
      {
        logo: themeMode === "light" ? TitanLogo : TitanLogoDark,
        name: "Titan",
        hasAccess: hasWealthSeriesTitan,
        values: [
          {
            name: "titan_growth",
            label: "Dynamic Upside",
            percentage: 11.8,
            id: portfolioHashMap["titan_growth"],
          },
          {
            name: "titan_low-risk-volatility",
            label: "Steady Guard",
            percentage: 8.6,
            id: portfolioHashMap["titan_low-risk-volatility"],
          },
          {
            name: "titan_fundamental",
            label: "Fundamental Edge",
            percentage: -2.3,
            id: portfolioHashMap["titan_fundamental"],
          },
        ],
      },
      {
        logo: themeMode === "light" ? EverestLogo : EverestLogoDark,
        name: "Everest",
        hasAccess: hasWealthSeriesEverest,
        values: [
          {
            name: "everest_growth",
            label: "Momentum Ascent",
            percentage: 11.8,
            id: portfolioHashMap["everest_growth"],
          },
          {
            name: "everest_low-risk-volatility",
            label: "Resilient Horizon",
            percentage: 8.6,
            id: portfolioHashMap["everest_low-risk-volatility"],
          },
          {
            name: "everest_fundamental",
            label: "Hidden Value Discovery",
            percentage: -2.3,
            id: portfolioHashMap["everest_fundamental"],
          },
          {
            name: "everest_defensive",
            label: "Capital Shield",
            percentage: -2.3,
            id: portfolioHashMap["everest_defensive"],
          },
          {
            name: "everest_balanced",
            label: "Steady Climb",
            percentage: -2.3,
            id: portfolioHashMap["everest_balanced"],
          },
        ],
      },
    ];
  }, [
    themeMode,
    hasWealthSeriesAtlas,
    hasWealthSeriesTitan,
    hasWealthSeriesEverest,
  ]);

  const menuLabel = useMemo(
    () => findLabelByPortfolioId(portfolio, tierData),
    [portfolio, tierData],
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = (tier, value) => {
    setSelectedPortfolio(value.name);
    history.push(
      `/wealth-series/${tier.name.toLowerCase()}/${value.name}${location.search}`,
    );
    handleClose();
  };

  const CustomBackdrop = styled(Backdrop)({
    '&[aria-hidden="true"]': {
      inert: true,
    },
  });
  const getTierAccess = () => {
    history.push("/wealth-series-signup");
  };

  const logoSrc =
    themeMode === "light"
      ? series === "atlas"
        ? AtlasLogo
        : series === "everest"
          ? EverestLogo
          : TitanLogo
      : series === "atlas"
        ? AtlasLogoDark
        : series === "everest"
          ? EverestLogoDark
          : TitanLogoDark;

  return (
    <div className="ws-portfolio-info">
      <div className="ws-portfolio-info-header">
        <div className="ws-portfolio-info-tier">
          <img
            src={logoSrc}
            alt={`${series.charAt(0).toUpperCase() + series.slice(1)}`}
            className={`ws-portfolio-info-tier-logo ${series}`}
          />
          <div className="ws-portfolio-info-tier-label" onClick={handleClick}>
            <span>{menuLabel}</span>
            {open ? (
              <ArrowDropUpIcon
                style={
                  themeMode === "light"
                    ? { color: "#007BFF" }
                    : { color: "#009EFF" }
                }
              />
            ) : (
              <ArrowDropDownIcon
                style={
                  themeMode === "light" ? { color: "#000" } : { color: "#FFF" }
                }
              />
            )}
          </div>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            className="ws-portfolio-info-tier-menu"
            disableAutoFocus
            disableRestoreFocus
            slots={{
              backdrop: CustomBackdrop,
            }}
          >
            {tierData.map((tier, index) => (
              <div
                className="ws-portfolio-tier-menu-wrapper"
                key={`tier-wrapper-${index}`}
              >
                <MenuItem
                  className={`ws-portfolio-tier-menu-logo-item ${tier.hasAccess ? "has-access" : ""}`}
                  onClick={() => {
                    if (!tier.hasAccess) {
                      getTierAccess(tier.name);
                    }
                  }}
                >
                  <img
                    src={tier.logo}
                    alt={`${tier.name} Logo`}
                    className="ws-portfolio-tier-logo"
                    style={
                      tier.name === "Everest" ? { width: "85px" } : undefined
                    }
                  />

                  {!tier.hasAccess && (
                    <div className="ws-portfolio-tier-no-access">
                      <LockIcon />
                      <div className="ws-portfolio-tier-no-access-text">
                        Get access
                      </div>
                    </div>
                  )}
                </MenuItem>

                {tier.values.map((value, index1) => (
                  <MenuItem
                    key={`tier-value-${tier.name}-${index1}`}
                    className="ws-portfolio-tier-menu-item"
                    onClick={() => handleClickMenuItem(tier, value)}
                    selected={value.name === selectedPortfolio}
                    disabled={!tier.hasAccess}
                  >
                    <div className="ws-portfolio-tier-value-label">
                      {value.label}
                    </div>
                    {/* {tier.hasAccess && (
                      <div
                        className={`ws-portfolio-tier-value-percentage ${value.percentage >= 0 ? "positive" : "negative"}`}
                      >
                        {value.percentage >= 0 && "+"}
                        {value.percentage}%
                      </div>
                    )} */}
                  </MenuItem>
                ))}
              </div>
            ))}
          </Menu>
        </div>

        <Tooltip
          placement="top"
          arrow
          title={isSubscribed ? "Unsubscribe from alerts" : "Get email alerts"}
        >
          <IconButton
            disabled={loadingSub}
            className="ws-portfolio-info-mail-icon"
            onClick={() => {
              handleSubscription(portfolioDatabaseId, !isSubscribed);
            }}
          >
            {isSubscribed ? <MarkEmailReadIcon /> : <MailOutlineIcon />}
          </IconButton>
        </Tooltip>
      </div>

      {isMobile && (
        <div className="ws-portfolio-header__timestamp">
          {loadingPortfolio ? (
            <BeautifulSkeleton width={350} height={20} />
          ) : (
            lastUpdated
          )}
        </div>
      )}
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  wealthPortfolios: state.wealthPortfolio,
  loadingPortfolio: state.wealthPortfolio.loading,
  companyDataLoading: state.companyData.companyDataLoading,
  tickerDetails: state.companyData.companyData.tickerDetails,
  loadingSub: state.portfolioSubscribe.loading,
  subscribedPortfolios: state.portfolioSubscribe.subscribedPortfolios,
});

const dispatchToProps = (dispatch) => ({
  fetchPortfolioData: (id) => dispatch(portfolioLoad(id)),
  handleSubscription: (id, subscribe) =>
    dispatch(portfolioSubscriptionRequest(id, subscribe)),
  fetchSubscribed: () => dispatch(fetchSubscribedPortfoliosRequest()),
});

export default connect(
  stateToProps,
  dispatchToProps,
)(WealthSeriesPortfolioInfo);
