import React, { useMemo } from "react";

import { Redirect, Route, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/Auth";
import PrivateLayout from "../components/common/Layout/PrivateLayout";

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  name,
  from,
  internal = false,
  platRoute = false,
  oaRoute = false,
  emeraldRoute = false,
  wealthRoute = false,
  ...rest
}) => {
  const {
    authUser,
    authToken,
    isUserBasic,
    tierLevel,
    loading,
    hasPlatinumProgram,
    hasOptionsAcademy,
    canSeeInternal,
    hasEmerald,
    hasWealthSeriesAtlas,
    hasWealthSeriesTitan,
    hasWealthSeriesEverest,
  } = useAuth();
  const location = useLocation();
  return useMemo(() => {
    // if it is an internal page and the user CANT see internal pages and user data is not loading - kick em out
    if (
      (internal && !canSeeInternal && !loading) ||
      (platRoute && !hasPlatinumProgram && !loading) ||
      (oaRoute && !hasOptionsAcademy && !loading) ||
      (emeraldRoute && !hasEmerald && !loading) ||
      (wealthRoute &&
        location.pathname.includes("everest") &&
        !hasWealthSeriesEverest &&
        !loading) ||
      (wealthRoute &&
        location.pathname.includes("atlas") &&
        !hasWealthSeriesAtlas &&
        !loading) ||
      (wealthRoute &&
        location.pathname.includes("titan") &&
        !hasWealthSeriesTitan &&
        !loading)
    ) {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{
                pathname: "/signout",
                state: { from: props.location },
              }}
            />
          )}
        />
      );
    }
    return (
      <Route
        {...rest}
        render={(props) =>
          authUser && authToken && isUserBasic ? (
            name !== "signOut" ? (
              <></>
            ) : (
              <>
                {rest.customLayout ? (
                  <Layout {...rest} name={name} from={from}>
                    <Component {...props} {...rest} />
                  </Layout>
                ) : (
                  <PrivateLayout {...rest} name={name} from={from}>
                    <Component {...props} {...rest} />
                  </PrivateLayout>
                )}
              </>
            )
          ) : authUser && authToken ? (
            <>
              {rest.customLayout ? (
                <Layout {...rest} name={name} from={from}>
                  <Component {...props} {...rest} />
                </Layout>
              ) : (
                <PrivateLayout {...rest} name={name} from={from}>
                  <Component {...props} {...rest} />
                </PrivateLayout>
              )}
            </>
          ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }, [authUser, Component, location.pathname, loading, tierLevel, hasEmerald]);
};

export default PrivateRoute;
