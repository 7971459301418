import React, { useMemo } from "react";
import { connect } from "react-redux";
import { isInternalWS } from "../../../../appRedux/actions/helpers";

function SimpleDisplay({
  comparator,
  ticker,
  currentPrices,
  dollar = true,
  showChangeClass = false,
}) {
  const currentPrice = useMemo(() => {
    const price = currentPrices.get(ticker) || comparator;
    return typeof price === "string" ? parseFloat(price) : price;
  }, [currentPrices, ticker, comparator]);

  const priceClass = useMemo(() => {
    if (showChangeClass) {
      return currentPrice > 0 ? "positive" : currentPrice < 0 ? "negative" : "";
    }
    return "";
  }, [currentPrice, showChangeClass]);

  return (
    <span className={priceClass}>
      {`${dollar ? "$" : ""}${Number(currentPrice).toFixed(2)}`}
    </span>
  );
}

const mapStateToProps = (state) => ({
  currentPrices: isInternalWS
    ? state.EquitiesSocket.currentPrices
    : state.dxFeedSocket.socketData,
});

export default connect(mapStateToProps, null)(SimpleDisplay);
