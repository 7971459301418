import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import { saga as socketSaga } from "../ducks/socket";
import { saga as equitiesSaga } from "../ducks/equities";
import { saga as atsEquitiesSaga } from "../ducks/atsEquities";
import { saga as gainersAndLoserSaga } from "../ducks/gainersLosers";
import { saga as darkflowSaga } from "../ducks/darkflow";
import { saga as marketStatusSaga } from "../ducks/marketStatus";
import { saga as orderBook } from "../ducks/orderbook";
import { saga as darkflowTableSaga } from "../ducks/darkflowTable";
import { saga as historicDarkflowSaga } from "../ducks/historicalDarkflow";
import { saga as pastStreamsSaga } from "../ducks/pastStreams";
import { saga as addUsernameSaga } from "../ducks/addUsername";
import { saga as radarTokenSaga } from "../ducks/radarToken";
import { saga as lastPriceSaga } from "../ducks/polyLastPrice";
import { saga as optionChainSaga } from "../ducks/optionChain";
import { saga as searchSymbols } from "../ducks/search";
import { saga as optionExpiry } from "../ducks/optionExpirations";
// import {saga as earningsCalendar} from "../ducks/earningsCalendar"
import { saga as optionSparkline } from "../ducks/optionSparklines";
import { saga as popularStocks } from "../ducks/popularStocks";
import { saga as dxFeedSocket } from "../ducks/dxFeedSocket";
import { saga as newLiveOptions } from "../ducks/newLiveOptions";
import { saga as companyData } from "../ducks/companyData";
import { saga as exclusiveMembers } from "../ducks/exclusiveMembers";
import { saga as swingTrades } from "../ducks/swingTrades";
import { saga as counter } from "../ducks/counter";
import { saga as updateLayout } from "../ducks/updateLayout";
import { saga as updateFields } from "../ducks/updateFields";
import { saga as userSettings } from "../ducks/userSettings";
import { saga as swingTradesOptions } from "../ducks/swingTradesOptions";
import { saga as optionAlertDates } from "../ducks/getOptionAlertDates";
import { saga as startEnginePoll } from "../ducks/startEnginePoll";
import { saga as chatParticipants } from "../ducks/chatParticipants";
import { saga as equitiesSocketSaga } from "../ducks/equitiesSocket";
import { saga as docusign } from "../ducks/docusigned";
import { saga as signedAgreement } from "../ducks/signedAgreement";
import { saga as tradeChat } from "../ducks/tradeChat";
import { saga as updateTradeStatus } from "../ducks/updateTradeStatus";
import { saga as swingSuggestions } from "../ducks/swingSuggestions";
import { saga as updateProfile } from "../ducks/updateProfile";
import { saga as contactForm } from "../ducks/contactForm";
import { saga as sparklineData } from "../ducks/sparklineData";
import { saga as topPicks } from "../ducks/topPicks";
import { saga as wealthNews } from "../ducks/wealthNews";
import { saga as wealthPortfolio } from "../ducks/wealthPortfolio";
import { saga as wealthPerformance } from "../ducks/wealthPerformance";
import { saga as portfolioSubscribe } from "../ducks/portfolioSubscription";
export default function* rootSaga() {
  yield all([
    authSagas(),
    socketSaga(),
    equitiesSaga(),
    atsEquitiesSaga(),
    gainersAndLoserSaga(),
    darkflowSaga(),
    marketStatusSaga(),
    orderBook(),
    darkflowTableSaga(),
    historicDarkflowSaga(),
    pastStreamsSaga(),
    addUsernameSaga(),
    radarTokenSaga(),
    lastPriceSaga(),
    optionChainSaga(),
    searchSymbols(),
    optionExpiry(),
    // earningsCalendar(),
    optionSparkline(),
    popularStocks(),
    dxFeedSocket(),
    newLiveOptions(),
    companyData(),
    exclusiveMembers(),
    swingTrades(),
    counter(),
    updateLayout(),
    updateFields(),
    userSettings(),
    docusign(),
    signedAgreement(),
    swingTradesOptions(),
    optionAlertDates(),
    startEnginePoll(),
    chatParticipants(),
    equitiesSocketSaga(),
    tradeChat(),
    updateTradeStatus(),
    swingSuggestions(),
    updateProfile(),
    contactForm(),
    sparklineData(),
    topPicks(),
    wealthNews(),
    wealthPortfolio(),
    wealthPerformance(),
    portfolioSubscribe(),
  ]);
}
