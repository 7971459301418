import React from "react";
import { connect } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import TALogo from "../../assets/images/products/logo-w-text.png";
import TALogoD from "../../assets/images/products/logo-w-text-dark.png";

import TADiamond from "../../assets/images/diamond.png";
import TACalculatorImg from "../../assets/images/products/terminal_calculator.png";

import "./TradealgoProduct.scss";

const TradealgoProduct = ({
  title,
  isLogoTitle = false,
  logo,
  description1,
  description2,
  description3,
  buttonLink,
  type,
  logoAlwaysDark = false,
  isDarkLogo = false,
  themeMode,
  checkout = false,
  isComingSoon = false,
}) => {
  return (
    <div className={`ta-product ${type}-product`}>
      <div className="ta-product__container">
        <div className="ta-product__inner">
          <div className="ta-product__header">
            {logoAlwaysDark === true ? (
              <img
                src={TALogoD}
                alt="ta-logo-d"
                className="ta-product-ta-logo"
              />
            ) : isDarkLogo === true ? (
              themeMode === "light" ? (
                <img
                  src={TALogoD}
                  alt="ta-logo-d"
                  className="ta-product-ta-logo"
                />
              ) : (
                <img
                  src={TALogo}
                  alt="ta-logo"
                  className="ta-product-ta-logo"
                />
              )
            ) : (
              <img src={TALogo} alt="ta-logo" className="ta-product-ta-logo" />
            )}

            {isComingSoon && (
              <div className="ta-product__coming-soon">Coming Soon</div>
            )}
          </div>

          <div className="ta-product__content">
            <div className="ta-product__title">
              {isLogoTitle ? (
                <img src={logo} alt="ta-logo" className="ta-product-logo" />
              ) : (
                title
              )}
            </div>
            <div className="ta-product__descriptions">
              <div className="ta-product__description1">{description1}</div>
              <div className="ta-product__description2">{description2}</div>
              {description3 && (
                <div className="ta-product__description2">{description3}</div>
              )}
            </div>
          </div>
        </div>
        {checkout ? (
          isComingSoon ? (
            <a href={buttonLink} rel="noopener noreferrer" target="_blank">
              <Button className="ta-product__button" variant="contained">
                Schedule Call
              </Button>
            </a>
          ) : (
            <a href={buttonLink} rel="noopener noreferrer">
              <Button className="ta-product__button" variant="contained">
                Purchase
              </Button>
            </a>
          )
        ) : (
          <Link to={buttonLink}>
            <Button className="ta-product__button" variant="contained">
              Learn More
            </Button>
          </Link>
        )}
      </div>

      {type === "terminal" && (
        <img
          src={TACalculatorImg}
          alt="ta-calculator"
          className="ta-product__calculator"
        />
      )}

      {type === "platinum" && (
        <img src={TADiamond} alt="ta-diamond" className="ta-product__diamond" />
      )}
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(TradealgoProduct);
