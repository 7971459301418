import React from "react";

import "./WSPortfolioActivities.scss";
import WSPortfolioActivity from "./WSPortfolioActivity";

const WSPortfolioActivities = ({ adjustments, loading }) => {
  return (
    <div className="ws-portfolio-activities">
      {(loading ? Array.from({ length: 1 }).fill(null) : adjustments)?.map(
        (item, index) => (
          <WSPortfolioActivity key={index} item={item} loading={loading} />
        ),
      )}

      <div className="ws-portfolio-activity__footer">
        No more items to display
      </div>
    </div>
  );
};

export default WSPortfolioActivities;
