import React, { useMemo, useState } from "react";
import "./Sentiment.scss";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";
import { connect } from "react-redux";
import moment from "moment";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Tooltip from "@mui/material/Tooltip";
import PieChartForSentiment from "./PieChartForSentiment";
import DiameterImg from "../../assets/images/diameter.png";
import NeedleImgLight from "../../assets/images/needle_light.png";
import NeedleImgDark from "../../assets/images/needle_dark.png";

function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}
export function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
}
function categorizeSentiment(score) {
  if (score >= 76) {
    return "Very Bullish";
  } else if (score >= 51) {
    return "Bullish";
  } else if (score >= 26) {
    return "Slightly Bullish";
  } else if (score >= -25 && score < 26) {
    return "Neutral";
  } else if (score >= -50) {
    return "Slightly Bearish";
  } else if (score >= -75) {
    return "Bearish";
  } else {
    return "Very Bearish";
  }
}

function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}

const SentimentDashboard = ({ news, newsSentiment, aiScores, themeMode }) => {
  const [value, setValue] = useState("ai_scores_60min");
  const [newsIndex, setNewsIndex] = useState(0);

  const handleLeftArrowClick = () => {
    setNewsIndex((prev) => prev - 1);
  };

  const handleRightArrowClick = () => {
    setNewsIndex((prev) => prev + 1);
  };
  const currNews = useMemo(() => {
    return news[newsIndex];
  }, [newsIndex, news]);
  const numericNewsSentiment = Number(newsSentiment);
  const numericNewsSentimentLabel =
    numericNewsSentiment > 0
      ? "+" +
        (isInt(numericNewsSentiment) === true
          ? numericNewsSentiment
          : numericNewsSentiment.toFixed(1))
      : isInt(numericNewsSentiment) === true
        ? numericNewsSentiment
        : numericNewsSentiment.toFixed(1);
  const disabledLeft = !news[newsIndex - 1];
  const disabledRight = !news[newsIndex + 1];
  const decodedTitle = decodeHtml(currNews?.title || "");
  const content = currNews?.description || "";

  const aiScore = useMemo(() => {
    return (
      aiScores?.find((item) => item?.table_name === value) || { sentiment: "" }
    );
  }, [aiScores, value]);
  const hasAiData = !!aiScore?.sentiment?.length;
  const sentimentLabel = hasAiData
    ? categorizeSentiment(aiScore.ai_score)
    : "Neutral";

  const numericNewsSentimentAngle = useMemo(() => {
    return (numericNewsSentiment * 120) / 100;
  }, [numericNewsSentiment]);

  return (
    <div
      className={`sentiment-dashboard ${!hasAiData ? "no-ai-data" : ""} ${!news.length ? "no-news-sentiment" : ""}`}
    >
      {hasAiData && (
        <div className={`overall-sentiment overall-sentiment-${themeMode}`}>
          <div className={`sentiment-overall sentiment-overall`}>
            Sentiment
            <Tooltip
              arrow={true}
              title={
                <React.Fragment>
                  The likelihood of certain events or outcomes is calculated
                  based on 20 years of historical data and financial metrics.
                  <br />
                  <br />
                  Based on the probabilities from both technical and financial
                  metrics, joint probability is used to determine whether the
                  Overall Sentiment is bullish or bearish sentiment.
                </React.Fragment>
              }
            >
              <ErrorOutlineIcon className="icon-space" />
            </Tooltip>
          </div>

          <div className="news-sentiment-container">
            <div className="news-sentiment-timestamp">
              <div
                className={`news-sentiment-timevalue ${value === "ai_scores_60min" ? "active" : ""}`}
                onClick={() => setValue("ai_scores_60min")}
              >
                1 Hour
              </div>
              <div
                className={`news-sentiment-timevalue ${value === "ai_scores_4h" ? "active" : ""}`}
                onClick={() => setValue("ai_scores_4h")}
              >
                4 Hour
              </div>
              <div
                className={`news-sentiment-timevalue ${value === "ai_scores_24h" ? "active" : ""}`}
                onClick={() => setValue("ai_scores_24h")}
              >
                1 Day
              </div>
            </div>
            <div className="news-sentiment-charts">
              <div className="news-ai-sentiment-chart">
                <div className="news-ai-sentiment-chart-title">
                  A.I. Sentiment
                </div>
                <PieChartForSentiment
                  data={[
                    aiScore?.bull_score ? aiScore?.bull_score.toFixed(2) : 0,
                    aiScore?.bear_score ? aiScore?.bear_score.toFixed(2) : 0,
                  ]}
                  percentage={Math.max(
                    aiScore?.bull_score ? aiScore?.bull_score.toFixed(2) : 0,
                    aiScore?.bear_score ? aiScore?.bear_score.toFixed(2) : 0,
                  )}
                  label={sentimentLabel}
                />
              </div>
              <div className="news-sentiment-details">
                <div className="news-sentiment-details-title">
                  News Sentiment
                </div>
                <div className="news-sentiment-details-content">
                  <img
                    src={DiameterImg}
                    alt="Diameter"
                    className="diameter-img"
                  />
                  <div className="needle-img-wrapper">
                    <img
                      src={
                        themeMode === "light" ? NeedleImgLight : NeedleImgDark
                      }
                      alt="Needle"
                      className="needle-img"
                      style={{
                        transform: `rotate(${numericNewsSentimentAngle}deg)`,
                      }}
                    />
                  </div>
                  <span className="news-sentiment-diameter-value positive-diameter-value">
                    +100
                  </span>
                  <span className="news-sentiment-diameter-value negative-diameter-value">
                    -100
                  </span>
                </div>
                <div className="news-sentiment-details-label">
                  Sentiment Score
                </div>
                <div
                  className={`news-sentiment-details-value ${numericNewsSentiment > 0 ? "positive" : "negative"}`}
                >
                  {numericNewsSentimentLabel}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!!news.length && (
        <div className={`news-section news-section-${themeMode}`}>
          <div className="events-header">
            <div className="header-left">
              Latest News
              {/* <Tooltip
                arrow={true}
                title={
                  <React.Fragment>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the
                    industry&apos;s standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and scrambled
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged. It was
                    popularised in the 1960s with the release of Letraset sheets
                    containing Lorem Ipsum passages, and more recently with
                    desktop publishing software like Aldus PageMaker including
                    versions of Lorem Ipsum.
                  </React.Fragment>
                }
              >
                <ErrorOutlineIcon className="icon-space" />
              </Tooltip> */}
            </div>
            <div className="pagination--buttons-wrapper">
              <IconButton
                className="pagination--button"
                disabled={disabledLeft}
                sx={{ opacity: disabledLeft ? 0.5 : 1 }}
                onClick={handleLeftArrowClick}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <IconButton
                className="pagination--button"
                disabled={disabledRight}
                sx={{ opacity: disabledRight ? 0.5 : 1 }}
                onClick={handleRightArrowClick}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            </div>
          </div>

          <div className="events-content">
            <div className="events-title">{decodedTitle || ""}</div>
            <div className="published">
              Published on{" "}
              {moment(currNews?.published_utc).format("MMM Do YYYY")}
            </div>
            <div className="news-content">
              {truncateText(content, 250) || ""}
            </div>
            <a
              href={currNews?.article_url}
              target="_blank"
              rel="noreferrer"
              className="read-more"
            >
              Read More
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

const stateToProps = (state) => ({
  // tickerDetails: state.companyData.companyData.tickerDetails,
  themeMode: state.themeMode.themeMode,
  companyDataLoading: state.companyData.companyDataLoading,
  news: state.companyData.companyData.news,
  newsSentiment: state.companyData.companyData.newsSentiment,
  aiScores: state.companyData.companyData.aiScores,
});

export default connect(stateToProps, null)(SentimentDashboard);
