import React, { useEffect, useMemo, useRef, useState } from "react";

import "./WealthSeriesSignUp.scss";
import WSGlowLogo from "../../assets/images/wealth-series-signup/logo.png";
import { Button, Modal } from "@material-ui/core";
import AtlasDarkLogo from "../../assets/images/wealth-series-signup/atlas-logo-dark.png";
import TitanDarkLogo from "../../assets/images/wealth-series-signup/titan-logo-dark.png";
import EverestDarkLogo from "../../assets/images/wealth-series-signup/everest-logo-dark.png";
import { connect } from "react-redux";
import { useAuth } from "../../contexts/Auth";
import BeautifulSkeleton from "../common/Skeletons/BeautifulSkeleton";
import moment from "moment";
import ContactForm from "../EmeraldSignup/ContactForm";

const WealthSeriesSignUp = ({ userSettings }) => {
  const { loading } = useAuth();

  const [open, setOpen] = useState(false);
  const [isInViewport, setIsInViewport] = useState(false);
  const targetRef = useRef(null);

  const checkIfInViewport = () => {
    const rect = targetRef.current.getBoundingClientRect();
    setIsInViewport(
      rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= window.innerHeight &&
        rect.right <= window.innerWidth,
    );
  };

  const openModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const hasSubmitted = useMemo(() => {
    const submitDate = userSettings?.wsContact;
    const weekSinceSubmit = moment(submitDate).add(1, "week");
    const today = moment();
    // Return true if today is before weekSinceSubmit (within a week since submitDate)
    return submitDate ? today.isBefore(weekSinceSubmit) : false;
  }, [userSettings, loading]);

  useEffect(() => {
    // Check if the element is in viewport on initial render
    checkIfInViewport();

    // Add a scroll event listener to check on scroll
    window.addEventListener("scroll", checkIfInViewport);
    window.addEventListener("resize", checkIfInViewport);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("scroll", checkIfInViewport);
      window.removeEventListener("resize", checkIfInViewport);
    };
  }, []);

  return (
    <div className="ws-sign-up">
      <div className="ws-sign-up__content">
        <img
          src={WSGlowLogo}
          alt="Wealth Series Logo"
          className="ws-glow-logo"
        />
        <div className="ws-signup-description">
          Leverage on cutting-edge AI technology coupled with advanced
          diversification techniques. Unlock maximum performance and
          resilience—every portfolio strategically diversifies across sectors
          and narratives, providing you with more robust protection and growth
          potential.
        </div>
        <div className="ws-signup-button-row">
          <div className="ws-signup-divider" />
          {loading ? (
            <BeautifulSkeleton
              className="no-transform-scale"
              width={300}
              height={50}
            />
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={`ws-signup-button ${isInViewport ? "ws-signup-button--visible" : ""}`}
              onClick={openModal}
              disabled={hasSubmitted}
            >
              {hasSubmitted ? "Access Requested" : "Schedule Call"}
            </Button>
          )}
        </div>
        <div className="ws-signup-tiers">
          <div className="ws-signup-tier ws-signup-tier--atlas">
            <div className="ws-signup-tier-header">
              <img
                src={AtlasDarkLogo}
                alt="Atlas Dark Logo"
                className="ws-signup-tier-logo"
              />
              <div className="ws-signup-tier-description">
                Powerful portfolios for steady and scalable success.
              </div>
            </div>
            <div className="ws-signup-tier-divider" />
            <div className="ws-signup-tier-benefits">
              <div className="ws-signup-tier-benefit-title">Benefits</div>
              <div className="ws-signup-tier-benefit">Growth Focused</div>
              <div className="ws-signup-tier-benefit">
                3 Portfolio Strategies
              </div>
            </div>
          </div>
          <div className="ws-signup-tier ws-signup-tier--titan">
            <div className="ws-signup-tier-header">
              <img
                src={TitanDarkLogo}
                alt="Titan Dark Logo"
                className="ws-signup-tier-logo"
              />
              <div className="ws-signup-tier-description">
                Optimized portfolios designed to protect & amplify your wealth.
              </div>
            </div>
            <div className="ws-signup-tier-divider" />
            <div className="ws-signup-tier-benefits">
              <div className="ws-signup-tier-benefit-title">Benefits</div>
              <div className="ws-signup-tier-benefit">Balance Focused</div>
              <div className="ws-signup-tier-benefit">
                3 Portfolio Strategies
              </div>
            </div>
          </div>
          <div className="ws-signup-tier ws-signup-tier--everest">
            <div className="ws-signup-tier-header">
              <img
                src={EverestDarkLogo}
                alt="Everest Dark Logo"
                className="ws-signup-tier-logo"
              />
              <div className="ws-signup-tier-description">
                An exclusive solution for unparalleled wealth management.
              </div>
            </div>
            <div className="ws-signup-tier-divider" />
            <div className="ws-signup-tier-benefits" ref={targetRef}>
              <div className="ws-signup-tier-benefit-title">Benefits</div>
              <div className="ws-signup-tier-benefit">
                Wealth Management Focused
              </div>
              <div className="ws-signup-tier-benefit">
                5 Portfolio Strategies
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ws-sign-up__disclaimer">
        This website provides AI-powered insights for informational purposes
        only. Please use at your own discretion, and consult a qualified
        financial advisor for personalized guidance.
      </div>

      <Modal open={open}>
        <ContactForm product_type="WEALTH SERIES" onClose={handleClose} />
      </Modal>
    </div>
  );
};

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

export default connect(stateToProps, null)(WealthSeriesSignUp);
