import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useAuth } from "../../contexts/Auth";
import "./SignIn.scss";
import Loader from "../common/Loader";
import logo from "./logoTa.png";
import { useSnackbar } from "notistack";

const url =
  process.env["REACT_APP_RELEASE_STAGE"] === "staging"
    ? "https://stag-frontend.tradealgo.com"
    : "https://tradealgo.com";

const SignIn = () => {
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = new URLSearchParams(window.location.search);
  const emailQueryParam = queryParams.get("email") || "";
  const [values, setValues] = useState({
    email: emailQueryParam,
  });
  const { sendPasswordResetEmail } = useAuth();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errored, setErrored] = useState(false);
  const resetPassword = async (email) => {
    try {
      // const response = await sendPasswordResetEmail(email);
      var actionCodeSettings = {
        url: `http://${process.env.REACT_APP_REDIRECT_DOMAIN}/signin`,
        handleCodeInApp: false,
      };
      Promise.race([
        sendPasswordResetEmail(email, actionCodeSettings),
        new Promise((resolve, reject) => setTimeout(reject, 5000)),
      ])
        .then((response) => {
          if (response) {
            setSubmitted(true);
          } else {
            setErrored(true);
          }
        })
        .catch((e) => {
          console.error(e);
          setErrored(true);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      resetPassword(values.email);
    }, 1000);
  };
  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  useEffect(() => {
    let message;
    let variant;
    if (errored) {
      message = "There was a problem resetting your password.";
      variant = "error";
    }
    if (submitted) {
      message = "Email has been sent successfully.";
      variant = "success";
    }
    if (message?.length) enqueueSnackbar(message, { variant });
  }, [submitted, errored]);

  return (
    <>
      <a style={{ cursor: "pointer" }} href={url}>
        <img className="logo-ta" alt="" height={36} src={logo} />
      </a>
      <div className="sign_in__page">
        <div className="right-part">
          <div className="big-caption">Reset Password</div>
          <div className={"contact-form"}>
            <div className={"input-form"}>
              <div className={"input-rows"}>
                <form onKeyDown={onKeyDownHandler} onSubmit={handleSubmit}>
                  <div className={"input-row"}>
                    <input
                      value={values.email}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          email: e.target.value,
                        })
                      }
                      type={"text"}
                      className={"input-field full-width"}
                      placeholder={"Name or E-mail"}
                    />
                  </div>
                  <div className={"input-row"}>
                    {loading ? (
                      <Loader
                        variant="light"
                        type="default"
                        size="sm"
                        style={{ display: "inline-block", fontSize: "8px" }}
                      />
                    ) : (
                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={loading || !values.email}
                        className="forgot-password-btn"
                      >
                        Send Email
                      </Button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
