import React from "react";
import { withRouter } from "react-router-dom";
import FallbackError from "./components/common/FallbackError";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({ hasError: false }); // Reset error state on route change
    }
  }

  render() {
    if (this.state.hasError) {
      console.trace();
      return (
        <div className="error-boundary-container container">
          <FallbackError />
        </div>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
