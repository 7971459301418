import React, { useEffect, useMemo, useRef, useState } from "react";
import "./WealthSeriesPortfolioTickerInfo.scss";
import { Tab, Tabs } from "@mui/material";
import ExampleChart from "../../../TradingView/ExampleChart";
import { capitalize } from "lodash";
import { connect } from "react-redux";
import { FundamentalData } from "react-tradingview-embed";
import { numberWithCommas } from "../../../utilities";
import BeautifulSkeleton from "../../../common/Skeletons/BeautifulSkeleton";
import { truncateText } from "../../../company/Sentiment";
import { eventEmitter } from "../../../TradingView/datafeed";
import { useAuth } from "../../../../contexts/Auth";

const WealthSeriesPortfolioTickerInfo = ({
  companyDataLoading,
  tickerDetails,
  themeMode,
  userSettings,
  portfolioTicker,
  marketStatus,
}) => {
  const [value, setValue] = useState(0);
  const [tickerPriceLow, setTickerPriceLow] = useState({});
  const [tickerPriceHigh, setTickerPriceHigh] = useState({});
  const [lastPrice, setLastPrice] = useState("");
  const chartRef = useRef(null);
  const financialsRef = useRef(null);
  const aboutRef = useRef(null);
  // We'll assume this container wraps the content. Adjust selector if needed.
  const scrollContainerRef = useRef(null);
  useEffect(() => {
    const container = document.querySelector(".ws-portfolio-content-container");
    scrollContainerRef.current = container;

    const handleScroll = () => {
      if (!container) return;

      const headerHeight = document.querySelector(
        ".ws-portfolio-ticker-info__header",
      ).offsetHeight;
      const scrollTop = container.scrollTop + headerHeight;

      // Check if we're at or near the bottom of the container
      const isAtBottom =
        container.scrollHeight - container.scrollTop - container.clientHeight <
        5;

      const sectionPositions = [
        { ref: chartRef, index: 0 },
        { ref: financialsRef, index: 1 },
        { ref: aboutRef, index: 2 },
      ].filter(({ ref }) => ref.current);

      let currentSectionIndex = 0;
      let smallestDistance = Infinity;

      // If we have an About section and we're at the bottom, just select About
      if (aboutRef.current && isAtBottom) {
        currentSectionIndex = 2;
      } else {
        // Otherwise, find the closest section as before
        sectionPositions.forEach(({ ref, index }) => {
          const offset = ref.current.offsetTop - container.offsetTop;
          const distance = Math.abs(offset - scrollTop);
          if (distance < smallestDistance) {
            smallestDistance = distance;
            currentSectionIndex = index;
          }
        });
      }

      // Update tab value if necessary
      if (currentSectionIndex !== value) {
        setValue(currentSectionIndex);
      }
    };

    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [value]);

  const tickerDetailsInfo = tickerDetails?.tickerDetails;
  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Scroll to the corresponding section
    let targetRef = null;
    if (newValue === 0) targetRef = chartRef;
    else if (newValue === 1) targetRef = financialsRef;
    else if (newValue === 2) targetRef = aboutRef;

    if (targetRef?.current && scrollContainerRef.current) {
      const headerHeight = document.querySelector(
        ".ws-portfolio-ticker-info__header",
      ).offsetHeight;

      const scrollableParent = scrollContainerRef.current;

      const targetPosition =
        targetRef.current.offsetTop -
        scrollContainerRef.current.offsetTop -
        headerHeight;

      scrollableParent.scrollTo({
        top: targetPosition,
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const memoizedFundamentalData = useMemo(
    () =>
      portfolioTicker ? (
        <FundamentalData
          className="hide-scroll"
          widgetProps={{
            symbol: portfolioTicker,
            colorTheme: themeMode,
            width: "100%",
            isTransparent: themeMode === "light",
          }}
        />
      ) : null,
    [themeMode, portfolioTicker],
  );
  const secPrice = tickerDetails?.secPrice;

  const ohlc = useMemo(() => {
    const open = secPrice?.open_price || 0;
    const high = secPrice?.bounds?.high || 0;
    const low = secPrice?.bounds?.low || 0;
    const close =
      marketStatus === "after" || marketStatus === "closed"
        ? secPrice?.close_price || 0
        : secPrice?.prev_close_price || 0;

    let lastPriceUsed = {
      "pre-open": parseFloat(lastPrice || secPrice?.last_price || 0).toFixed(2),
      open: parseFloat(lastPrice || secPrice?.last_price || 0).toFixed(2),
      after: parseFloat(
        secPrice?.close_price
          ? secPrice?.close_price
          : lastPrice || secPrice?.last_price || 0,
      ).toFixed(2),
      closed: parseFloat(secPrice?.close_price || 0).toFixed(2),
    }[marketStatus];

    let compare = {
      "pre-open": parseFloat(
        secPrice?.pre_open || secPrice?.prev_close_price,
      ).toFixed(2),
      open: parseFloat(secPrice.open_price).toFixed(2),
      after: parseFloat(secPrice.open_price).toFixed(2),
      closed: parseFloat(secPrice.open_price).toFixed(2),
    }[marketStatus];

    return {
      open,
      high,
      low,
      close,
      closeType: secPrice?.close_price ? "Close" : "Previous Close",
      performancePercent: (
        ((lastPriceUsed - compare) / compare) *
        100
      )?.toFixed(2),
      lp: lastPriceUsed,
      change: Number(lastPriceUsed - compare).toFixed(2),
      positivePerformance: ((lastPriceUsed - compare) / compare) * 100 > 0,
      secTicker: secPrice.bounds.ticker,
    };
  }, [secPrice, marketStatus, lastPrice, portfolioTicker]);

  useEffect(() => {
    const keys = Object.keys(tickerPriceHigh);
    if (
      lastPrice >= ohlc.high &&
      portfolioTicker === ohlc.secTicker &&
      !!lastPrice
    ) {
      if (!keys.length) {
        setTickerPriceHigh({
          [ohlc.secTicker]: lastPrice,
        });
      } else {
        if (tickerPriceHigh[portfolioTicker] < lastPrice) {
          setTickerPriceHigh({
            [ohlc.secTicker]: lastPrice,
          });
        }
      }
    }
  }, [ohlc, portfolioTicker, lastPrice]);
  useEffect(() => {
    const keys = Object.keys(tickerPriceLow);
    if (
      lastPrice <= ohlc.low &&
      portfolioTicker === ohlc.secTicker &&
      !!lastPrice
    ) {
      if (!keys.length) {
        setTickerPriceLow({
          [ohlc.secTicker]: lastPrice,
        });
      } else {
        if (tickerPriceLow[portfolioTicker] > lastPrice) {
          setTickerPriceLow({
            [ohlc.secTicker]: lastPrice,
          });
        }
      }
    }
  }, [ohlc, portfolioTicker, lastPrice]);

  useEffect(() => {
    setTickerPriceHigh({});
    setTickerPriceLow({});
  }, [portfolioTicker]);

  useEffect(() => {
    const handlePriceUpdate = (event) => {
      const currentSub = event.detail;
      if (
        !!currentSub &&
        (currentSub?.eventSymbol?.includes(portfolioTicker) ||
          currentSub?.symbol?.includes(portfolioTicker))
      ) {
        setLastPrice(currentSub.close);
      }
    };
    eventEmitter.addEventListener("priceUpdate", handlePriceUpdate);

    return () => {
      eventEmitter.removeEventListener("priceUpdate", handlePriceUpdate);
    };
  }, [portfolioTicker, setLastPrice, themeMode]);

  const highToShow = useMemo(() => {
    const keys = Object.keys(tickerPriceHigh);
    if (
      !!keys.length &&
      keys[0] === ohlc.secTicker &&
      ohlc.high < tickerPriceHigh[ohlc.secTicker] &&
      portfolioTicker === keys[0]
    ) {
      return tickerPriceHigh[ohlc.secTicker];
    }
    return ohlc.high;
  }, [portfolioTicker, tickerPriceHigh, ohlc]);

  const lowToShow = useMemo(() => {
    const keys = Object.keys(tickerPriceLow);
    if (
      !!keys.length &&
      keys[0] === ohlc.secTicker &&
      ohlc.low > tickerPriceLow[ohlc.secTicker] &&
      portfolioTicker === keys[0]
    ) {
      return tickerPriceLow[ohlc.secTicker];
    }
    return ohlc.low;
  }, [portfolioTicker, tickerPriceLow, ohlc]);

  useEffect(() => {
    if (portfolioTicker === ohlc.secTicker) {
      setLastPrice(secPrice.last_price);
    } else {
      setLastPrice();
    }
  }, [portfolioTicker, secPrice]);

  const [isBlurred, setIsBlurred] = useState(false);
  const { isDemo } = useAuth();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setIsBlurred(searchParams.get("blurred") === "true" || isDemo);
  }, [location, isDemo]);

  return (
    <>
      <div className="ws-portfolio-ticker-info__header">
        <div className="ws-portfolio-ticker-general-info">
          <div className="flex align-center" style={{ gap: 8 }}>
            <div
              className={`ws-portfolio-ticker-name ${isBlurred ? "blurred" : ""}`}
            >
              {portfolioTicker}
            </div>
            <div className="ws-portfolio-ticker-company">
              {companyDataLoading ? (
                <BeautifulSkeleton width={205} height={20} />
              ) : (
                <span className={`${isBlurred ? "blurred" : ""}`}>
                  {truncateText(tickerDetails.tickerDetails.name, 45)}
                </span>
              )}
            </div>
          </div>
          <div className="ws-portfolio-ticker-price">
            <span className="ws-portfolio-ticker-price__value">
              <span className="ws-portfolio-ticker-current_price">
                {companyDataLoading ? (
                  <BeautifulSkeleton width={60} height={24} />
                ) : (
                  "$" + Number(ohlc.lp)?.toFixed(2)
                )}
              </span>
              <span className="ws-portfolio-ticker-usd">USD</span>
            </span>
            <span className="ws-portfolio-ticker-price__change">
              {companyDataLoading ? (
                <>
                  <BeautifulSkeleton width={55} height={24} />
                  <BeautifulSkeleton width={55} height={24} />
                </>
              ) : (
                <>
                  <span
                    className={`ws-portfolio-ticker-price__change-value price-${ohlc.positivePerformance ? "positive" : "negative"}`}
                  >
                    {ohlc.positivePerformance && "+"}({ohlc.change})
                  </span>
                  <span
                    className={`ws-portfolio-chip ${ohlc.positivePerformance ? "positive" : "negative"}`}
                  >
                    {ohlc.positivePerformance && "+"}
                    {ohlc.performancePercent}%
                  </span>
                </>
              )}
            </span>
          </div>
        </div>

        <Tabs
          value={value}
          onChange={handleChange}
          className="w-full ws-portfolio-ticker-info-tabs"
        >
          <Tab label="Overview" />
          <Tab label="Financials" />
          {tickerDetailsInfo.description && <Tab label="About" />}
        </Tabs>
      </div>

      <div
        className="ws-portfolio-ticker-info__content blurred-tv"
        ref={chartRef}
      >
        <div className="ws-portfolio-ticker-performances">
          <div className="ws-portfolio-ticker-performance">
            <div className="ws-portfolio-ticker-performance__title">
              {ohlc.closeType}
            </div>
            <div className="ws-portfolio-ticker-performance__value">
              {companyDataLoading ? (
                <BeautifulSkeleton width={50} animation="wave" />
              ) : (
                `$${Number(ohlc.close).toFixed(2)}`
              )}
            </div>
          </div>
          <div className="ws-portfolio-ticker-performance">
            <div className="ws-portfolio-ticker-performance__title">Open</div>
            <div className="ws-portfolio-ticker-performance__value">
              {companyDataLoading ? (
                <BeautifulSkeleton width={50} animation="wave" />
              ) : (
                `$${Number(ohlc.open).toFixed(2)}`
              )}
            </div>
          </div>
          <div className="ws-portfolio-ticker-performance">
            <div className="ws-portfolio-ticker-performance__title">
              Performance
            </div>
            {companyDataLoading ? (
              <BeautifulSkeleton width={75} height={23.9} animation="wave" />
            ) : (
              <span
                className={`ws-portfolio-chip ${ohlc.positivePerformance ? "positive" : "negative"}`}
              >
                {ohlc.positivePerformance && "+"}
                {Number(ohlc.performancePercent).toFixed(2)}%
              </span>
            )}
          </div>
          <div className="ws-portfolio-ticker-performance">
            <div className="ws-portfolio-ticker-performance__title">
              Day Range
            </div>
            <div className="ws-portfolio-ticker-performance__value">
              {companyDataLoading ? (
                <BeautifulSkeleton width={110} animation="wave" />
              ) : (
                `$${Number(lowToShow).toFixed(2) + " - $" + Number(highToShow).toFixed(2)}`
              )}
            </div>
          </div>
        </div>

        <div className="ws-portfolio-divider" />

        <div id="ws-portfolio-ticker-chart" className="w-full">
          {portfolioTicker && (
            <ExampleChart
              symbol={portfolioTicker}
              themeMode={capitalize(themeMode)}
              userSettings={userSettings}
            />
          )}
        </div>

        <div className="ws-portfolio-divider" />

        <div
          id="ws-portfolio-ticker-financials"
          className={`w-full ${isBlurred ? "blurred" : ""}`}
          ref={financialsRef}
        >
          {memoizedFundamentalData}
        </div>

        <div className="ws-portfolio-divider" />

        {tickerDetailsInfo?.description && (
          <div id="ws-portfolio-ticker-about" className="w-full" ref={aboutRef}>
            <div className="ws-portfolio-ticker-about__title">About</div>
            <div
              className={`ws-portfolio-ticker-about__description ${isBlurred ? "blurred" : ""}`}
            >
              {tickerDetailsInfo?.description}
            </div>
            <div className="ws-portfolio-divider" />
            <div className="ws-portfolio-ticker-about__infos">
              <div className="ws-portfolio-ticker-about__info">
                <div className="ws-portfolio-ticker-about__info-title">
                  Sector
                </div>
                <div className="ws-portfolio-ticker-about__info-value">
                  {tickerDetailsInfo?.sector}
                </div>
              </div>
              <div className="ws-portfolio-ticker-about__info">
                <div className="ws-portfolio-ticker-about__info-title">
                  Industry
                </div>
                <div className="ws-portfolio-ticker-about__info-value">
                  {tickerDetailsInfo?.industry}
                </div>
              </div>
              <div className="ws-portfolio-ticker-about__info">
                <div className="ws-portfolio-ticker-about__info-title">
                  Employees
                </div>
                <div className="ws-portfolio-ticker-about__info-value">
                  {tickerDetailsInfo?.total_employees
                    ? numberWithCommas(tickerDetailsInfo?.total_employees)
                    : "Not Available"}
                </div>
              </div>
              <div className="ws-portfolio-ticker-about__info">
                <div className="ws-portfolio-ticker-about__info-title">
                  Website
                </div>
                <div
                  className={`ws-portfolio-ticker-about__info-value ${isBlurred ? "blurred" : ""}`}
                >
                  <a
                    href={tickerDetailsInfo?.homepage_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {tickerDetailsInfo?.homepage_url}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
  portfolioTicker: state.portfolioTicker.symbol,
  tickerDetails: state.companyData.companyData,
  companyDataLoading: state.companyData.companyDataLoading,
  marketStatus: state.companyData.companyData.marketState,
});

export default connect(stateToProps, null)(WealthSeriesPortfolioTickerInfo);
