import { all, put, takeEvery, fork, delay, call } from "redux-saga/effects";
import moment from "moment";
import { getHolidays } from "nyse-holidays";

function randomNumber(min, max) {
  return Math.random() * (max - min) + min;
}

// Action Types
export const MARKET_STATUS_CALCULATE = "MARKET_STATUS/CALCULATE";
export const MARKET_STATUS_SET = "MARKET_STATUS/SET";

// Action Creators
export const marketstatusCalculate = () => {
  return {
    type: MARKET_STATUS_CALCULATE,
  };
};
export const marketstatusSet = (marketStatusData) => {
  return {
    type: MARKET_STATUS_SET,
    payload: marketStatusData,
  };
};

let timeToMarketChange = 1;

const marketStatusByTime = (state) => {
  const currentTime = moment().tz("America/New_York").format("HH:mm:ss");
  const currentDate = moment(currentTime, "HH:mm:ss").tz("America/New_York");

  if (
    currentDate.isBetween(
      moment("16:00:00", "HH:mm:ss").tz("America/New_York"),
      moment("20:00:00", "HH:mm:ss").tz("America/New_York"),
    )
  ) {
    // After Hours (4pm ~ 8pm )
    return "after";
  }

  if (
    currentDate.isBetween(
      moment("20:00:00", "HH:mm:ss").tz("America/New_York"),
      moment("24:00:00", "HH:mm:ss").tz("America/New_York"),
    )
  ) {
    // Market Closed Hours ( 8pm ~ 4am )
    return "closed";
  }

  if (
    currentDate.isBetween(
      moment("00:00:00", "HH:mm:ss").tz("America/New_York"),
      moment("04:00:00", "HH:mm:ss").tz("America/New_York"),
    )
  ) {
    // Market Closed Hours ( 8pm ~ 4am )
    return "closed";
  }

  if (
    currentDate.isBetween(
      moment("09:30:00", "HH:mm:ss").tz("America/New_York"),
      moment("16:00:00", "HH:mm:ss").tz("America/New_York"),
    )
  ) {
    // Market open hours ( 9:30am ~ 4pm )
    return "open";
  }

  if (
    currentDate.isBetween(
      moment("04:00:00", "HH:mm:ss"),
      moment("09:30:00", "HH:mm:ss"),
    )
  ) {
    // Market Status pre-open hours ( 4am ~ 9:30am )
    return "pre-open";
  }
  return state;
};

const getMarketStatus = (initialState) => {
  const today = moment().tz("America/New_York");
  const marketATSPreMarketTime = moment.tz(
    "04:00:00",
    "HH:mm:ss",
    "America/New_York",
  );
  const marketOpenTime = moment.tz("09:30:00", "HH:mm:ss", "America/New_York");
  let marketAfterHoursTime = moment.tz(
    "16:00:00",
    "HH:mm:ss",
    "America/New_York",
  );
  let marketCloseTime = moment.tz("20:00:00", "HH:mm:ss", "America/New_York");
  let state = initialState;
  let comparator, notOpenToday, ttmc; // ttmc = time to market change

  if (!initialState) {
    // Weekend check:
    let dayOfWeek = today.day();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      notOpenToday = true;
      state = "closed";
    }

    // Holiday and early closure check:
    let todayDate = today.format("YYYY-MM-DD");
    let tomorrowDate = today.add(1, "days").format("YYYY-MM-DD");
    let todayYear = today.format("YYYY");

    const holidays = getHolidays(todayYear);
    holidays.forEach((holiday) => {
      if (holiday.dateString === todayDate) {
        notOpenToday = true;
        state = "closed";
      } else if (
        holiday.dateString === tomorrowDate &&
        (holiday.name === "Independence Day" ||
          holiday.name === "Thanksgiving Day" ||
          holiday.name === "Christmas Day") &&
        state !== "closed"
      ) {
        marketAfterHoursTime = moment.tz(
          "13:00:00",
          "HH:mm:ss",
          "America/New_York",
        );
        marketCloseTime = moment.tz("17:00:00", "HH:mm:ss", "America/New_York");
      }
    });
  }

  if (!state) {
    if (
      today.isBefore(marketATSPreMarketTime) &&
      today.isSameOrAfter(marketCloseTime)
    ) {
      // if the time is before 4am or after 8pm est
      state = "closed";
    } else if (
      today.isSameOrAfter(marketATSPreMarketTime) &&
      today.isBefore(marketOpenTime)
    ) {
      // if the time is between 4am and 9:30am, use pre
      state = "pre-open";
    } else if (
      today.isSameOrAfter(marketAfterHoursTime) &&
      today.isBefore(marketCloseTime)
    ) {
      // if the time is between 4pm and 8pm, use after
      state = "after";
    } else {
      // if the time is after 9:30, use open
      state = "open";
    }
  }

  switch (state) {
    case "pre-open":
      comparator = marketOpenTime;
      break;
    case "open":
      comparator = marketAfterHoursTime;
      break;
    case "after":
      comparator = marketCloseTime;
      break;
    case "closed":
      comparator = marketATSPreMarketTime;
      // determine which date's premarket to use
      if (marketATSPreMarketTime.diff(today) < 0) {
        comparator.add(1, "days");
      }
      break;
  }

  ttmc = comparator.diff(today);

  return { state, ttmc, notOpenToday };
};
function* marketStatusCalculation() {
  const closedData = getMarketStatus();
  let marketStatus;

  if (closedData?.ttmc) {
    timeToMarketChange = closedData.ttmc;
  }
  if (timeToMarketChange < 0) {
    timeToMarketChange = randomNumber(0, 2000);
  }

  if (closedData?.state === "closed") {
    marketStatus = "closed";
  } else {
    marketStatus = marketStatusByTime(closedData.state);
  }

  // new addition: derive wasOpenToday from notOpenToday
  const wasOpenToday = !closedData?.notOpenToday;

  // pass an object with both status and wasOpenToday
  return yield put(
    marketstatusSet({
      marketStatus,
      wasOpenToday,
    }),
  );
}

function* marketUpdateLoop() {
  while (timeToMarketChange) {
    yield delay(timeToMarketChange);
    yield call(marketStatusCalculation);
  }
}

function* listenMarketStatus() {
  yield takeEvery(MARKET_STATUS_CALCULATE, marketStatusCalculation);
}

// Root Saga
export function* saga() {
  yield all([fork(listenMarketStatus), fork(marketUpdateLoop)]);
}

const INIT_STATE = {
  marketStatus: marketStatusByTime(), // existing
  wasOpenToday: false, // new key (default false)
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MARKET_STATUS_SET: {
      const { marketStatus, wasOpenToday } = action.payload;

      return {
        ...state,
        marketStatus,
        wasOpenToday,
      };
    }
    default:
      return state;
  }
};

export default reducer;
