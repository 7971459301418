import React from "react";

import "./WSContactSupport.scss";
import { Tooltip } from "@material-ui/core";
import EmailIcon from "@mui/icons-material/Email";

const WSContactSupport = ({ isMobile = false }) => {
  const sendEmail = () => {
    window.location.href = "mailto:support@tradealgo.com";
  };

  return (
    <div
      className={`ws-contact-support-button ${isMobile ? "ws-contact-support-button--mobile" : ""}`}
      onClick={sendEmail}
    >
      <Tooltip
        title="Need assistance? Reach out to our Wealth Series team for priority support"
        placement="bottom"
        arrow
      >
        <div className="ws-contact-support-button__wrapper">
          <EmailIcon />
          <span>Contact Support</span>
          <span className="ws-contact-support-chip">Priority</span>
        </div>
      </Tooltip>
    </div>
  );
};

export default WSContactSupport;
