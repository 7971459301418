import React, { useMemo } from "react";
import { connect } from "react-redux";
import { isInternalWS } from "../../../../appRedux/actions/helpers";
import { decimal } from "../../../homePage/TradeAlgoCard";
import "../../../WealthSeriesPortfolioPage/components/WealthSeriesPortfolioTickerInfo/WealthSeriesPortfolioTickerInfo.scss"; // Import the styles for the chip

function PriceDisplay({
  lastPrice,
  trend_start,
  showPercent = false, // Boolean to toggle between percent and pure change
  ticker,
  currentPrices,
  className = false,
  withParentheses = false, // Optional prop to add parentheses around the value
  asChip = false, // Optional prop to display as a chip
}) {
  // Use memoization to compute the current price efficiently
  const currentPrice = useMemo(() => {
    return currentPrices.get(ticker) || lastPrice;
  }, [currentPrices, ticker, lastPrice]);

  // Calculate performance change and percentage
  const performanceChange = useMemo(
    () => currentPrice - trend_start,
    [currentPrice, trend_start],
  );

  const performancePercent = useMemo(() => {
    return Math.round((currentPrice / trend_start - 1) * 1000) / 10;
  }, [currentPrice, trend_start]);

  // Determine which value to show based on the boolean flag
  const displayValue = `${performanceChange > 0 ? "+" : ""}${decimal(showPercent ? performancePercent : performanceChange)}${showPercent ? "%" : ""}`;

  // Wrap the display value in parentheses if the withParentheses prop is true
  const formattedDisplayValue = withParentheses
    ? `(${displayValue})`
    : displayValue;

  const classNameMemo = useMemo(() => {
    let classes = "";
    if (className) {
      classes += `price-${performanceChange > 0 ? "positive" : "negative"}`;
    }
    if (asChip) {
      classes += ` ws-portfolio-chip ${performanceChange >= 0 ? "positive" : "negative"}`;
    }
    return classes.trim();
  }, [performanceChange, className, asChip]);

  return <span className={classNameMemo}>{formattedDisplayValue}</span>;
}

const stateToProps = (state) => ({
  currentPrices: isInternalWS
    ? state.EquitiesSocket.currentPrices
    : state.dxFeedSocket.socketData,
});

export default connect(stateToProps, null)(PriceDisplay);
