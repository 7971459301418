import React, { useEffect } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { popularStocksLoad } from "../../../appRedux/ducks/popularStocks";
import "./PopularStocks.scss";
import IndividualPopStockRow from "./IndividualPopStockRow";
import BeautifulSkeleton from "../../common/Skeletons/BeautifulSkeleton";

const useStyles = makeStyles((theme) => ({
  skeleton: {
    backgroundColor: theme.palette.primary.skeleton,
    width: "calc(100% - 20px)",
    margin: 10,
  },
}));

const PopularStocks = ({
  getPopularStocks,
  popularStocks,
  loading,
  wasOpenToday,
}) => {
  const classes = useStyles();
  const hardHistory = useHistory();

  const dataMoment = moment.tz("America/New_York");
  const today = dataMoment.format("MMMM Do, YYYY");

  const sortByPerformance = (stocks) => {
    return stocks.sort((a, b) => b.performanceRatio - a.performanceRatio);
  };

  const goCompanyDetailPage = (symbol) => {
    hardHistory.push(`/company/${symbol}`);
  };

  useEffect(() => {
    getPopularStocks();
  }, []);
  return (
    <div className="popular-stocks">
      <div className="popular-stocks-header">
        <div className="popular-stocks-title">
          Popular Stocks
          <Tooltip
            arrow={true}
            title="Tickers are chosen based on a mixed weightage of trade volume, and user search activity."
          >
            <ErrorOutlineIcon className="icon-space" />
          </Tooltip>
        </div>
        <div className="popular-stocks-timestamp">
          {wasOpenToday ? today : ""}
        </div>
      </div>

      <div className="popular-stocks-content">
        <div
          className={`popular-stocks-content--inner ${loading ? "skeleton-loading" : ""}`}
        >
          {loading ? (
            <>
              <BeautifulSkeleton
                className={classes.skeleton}
                variant="rect"
                height={40}
              />
              <BeautifulSkeleton
                className={classes.skeleton}
                variant="rect"
                height={40}
              />
              <BeautifulSkeleton
                className={classes.skeleton}
                variant="rect"
                height={40}
              />
              <BeautifulSkeleton
                className={classes.skeleton}
                variant="rect"
                height={40}
              />
              <BeautifulSkeleton
                className={classes.skeleton}
                variant="rect"
                height={40}
              />
              <BeautifulSkeleton
                className={classes.skeleton}
                variant="rect"
                height={40}
              />
              <BeautifulSkeleton
                className={classes.skeleton}
                variant="rect"
                height={40}
              />
              <BeautifulSkeleton
                className={classes.skeleton}
                variant="rect"
                height={40}
              />
            </>
          ) : (
            popularStocks &&
            popularStocks.length > 0 &&
            sortByPerformance(popularStocks).map((stock, index) => (
              <IndividualPopStockRow
                goCompanyDetailPage={goCompanyDetailPage}
                item={stock}
                key={index}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  popularStocks: state.popularStocks.popularStocks,
  loading: state.popularStocks.loading,
  wasOpenToday: state.marketStatus.wasOpenToday,
});
const dispatchToProps = (dispatch) => ({
  getPopularStocks: () => dispatch(popularStocksLoad()),
});

export default connect(stateToProps, dispatchToProps)(PopularStocks);
