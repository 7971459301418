export const portfolioHashMap = {
  "everest_low-risk-volatility": "4676c2de-175b-41db-b483-b5ad693639a8",
  everest_defensive: "889f1ae5-0f57-42d9-8c10-95091c347f50",
  everest_balanced: "8088d7be-7bfd-491a-843a-45265610d985",
  everest_growth: "102a11f1-a045-4c33-aa0f-c8ba0d623f56",
  everest_fundamental: "c23827ab-1d16-461e-9e2f-573e77fd5a1c",
  titan_growth: "44840d75-6e8f-427d-a960-3f33a8e1fa24",
  "titan_low-risk-volatility": "71870b82-8bfd-4f3c-af27-d8b9f56b1bdf",
  titan_fundamental: "13a20efe-1083-4a62-9dde-725f4f4f5eec",
  atlas_growth: "f7b72dd8-6178-4c7f-8338-1252af29903c",
  "atlas_low-risk-volatility": "b6daaac0-308b-4349-9eba-f8445fd29b3c",
  atlas_fundamental: "c8da575c-df6a-466c-ab5c-73e8fab23cd2",
};

export const reversePortfolioHashMap = Object.fromEntries(
  Object.entries(portfolioHashMap).map(([key, value]) => [value, key]),
);
