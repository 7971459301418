import React, { useEffect, useState } from "react";
import "./WealthSeriesPortfolioKeyAdjustments.scss";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { connect } from "react-redux";
import { portfolioSymbolSetter } from "../../../../appRedux/ducks/portfolioTicker";
import moment from "moment";
import { useParams } from "react-router-dom";
import { init_portfolio } from "../../../../appRedux/ducks/wealthPortfolio";
import BeautifulSkeleton from "../../../common/Skeletons/BeautifulSkeleton";
import FallbackTickerIcon from "../../../common/FallbackTickerIcon/FallbackTickerIcon";
import { useAuth } from "../../../../contexts/Auth";

const WealthSeriesPortfolioKeyAdjustments = ({
  setTicker,
  portfolioTicker,
  wealthPortfolios,
  loadingPortfolio,
}) => {
  const [isBlurred, setIsBlurred] = useState(false);
  const { isDemo } = useAuth();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setIsBlurred(searchParams.get("blurred") === "true" || isDemo);
  }, [location]);
  const { portfolio } = useParams();
  const sortedAdjustments = (
    (wealthPortfolios[portfolio] || init_portfolio)?.adjustments || []
  )
    ?.map((adjustment) => ({
      ...adjustment,
      sortedTickers: Object.entries(adjustment.adjustments)
        ?.map(([ticker, { old, new: newP, diff }]) => ({
          ticker,
          oldPercent: (old * 100).toFixed(2),
          newPercent: (newP * 100).toFixed(2),
          difference: (diff * 100).toFixed(2),
        }))
        ?.sort((a, b) => Math.abs(b.difference) - Math.abs(a.difference)),
    }))
    ?.sort((a, b) => new Date(b.rebalance_date) - new Date(a.rebalance_date));

  return (
    <div className="ws-portfolio-key-adjustments">
      <div className="ws-portfolio-key-adjustments__header">
        Key Portfolio Adjustments
      </div>

      <div className="ws-portfolio-key-adjustments__content ws-scroll-element">
        {(loadingPortfolio
          ? Array.from({ length: 1 }).fill(null)
          : sortedAdjustments
        )?.map((data, index) => (
          <div key={index} className="ws-portfolio-key-adjustment">
            <div className="ws-portfolio-key-adjustment__time">
              {loadingPortfolio ? (
                <BeautifulSkeleton width={170} height={20} />
              ) : (
                moment(data.rebalance_date).format("dddd, MMM DD, YYYY")
              )}
            </div>
            <div className="w-full">
              {(loadingPortfolio
                ? Array.from({ length: 5 }).fill(null)
                : data.sortedTickers
              )?.map((tickerData, index1) => {
                if (loadingPortfolio) {
                  return (
                    <div
                      key={index1}
                      className={`ws-portfolio-key-adjustment__item`}
                    >
                      <div className="ws-portfolio-key-adjustment__ticker">
                        <BeautifulSkeleton
                          variant="circular"
                          width={24}
                          height={24}
                        />
                        <BeautifulSkeleton width={80} height={32} />
                      </div>
                      <div className="ws-portfolio-key-adjustment__percent">
                        <BeautifulSkeleton width={150} height={32} />
                      </div>
                      <div className={`ws-portfolio-key-adjustment__change`}>
                        <BeautifulSkeleton width={70} height={32} />
                      </div>
                    </div>
                  );
                } else {
                  const { ticker, oldPercent, newPercent, difference } =
                    tickerData;
                  return (
                    <div
                      onClick={() => setTicker(ticker)}
                      key={index1}
                      className={`ws-portfolio-key-adjustment__item ${ticker === portfolioTicker ? "active" : ""}`}
                    >
                      <div
                        className={`ws-portfolio-key-adjustment__ticker ${isBlurred ? "blurred" : ""}`}
                      >
                        <FallbackTickerIcon
                          ticker={ticker}
                          className="ws-portfolio-key-adjustment__ticker-logo"
                          src={`https://assets.tradealgo.com/stock-logos/${ticker}.png`}
                        />
                        <div>{ticker}</div>
                        {oldPercent === "0.00" && (
                          <div className="ws-portfolio-new-adjustment">New</div>
                        )}
                      </div>
                      <div className="ws-portfolio-key-adjustment__percent">
                        <div className="ws-portfolio-key-adjustment__percent-prev">
                          {oldPercent}%
                        </div>
                        <ArrowRightAltIcon style={{ fillOpacity: 0.56 }} />
                        <div className="ws-portfolio-key-adjustment__percent-new">
                          {newPercent}%
                        </div>
                      </div>
                      <div
                        className={`ws-portfolio-key-adjustment__change ${difference.startsWith("-") ? "negative" : "positive"}`}
                      >
                        {difference.startsWith("-") ? "" : "+"}
                        {difference}%
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  portfolioTicker: state.portfolioTicker.symbol,
  wealthPortfolios: state.wealthPortfolio,
  loadingPortfolio: state.wealthPortfolio.loading,
});

const dispatchToProps = (dispatch) => ({
  setTicker: (sym) => dispatch(portfolioSymbolSetter(sym)),
});

export default connect(
  stateToProps,
  dispatchToProps,
)(WealthSeriesPortfolioKeyAdjustments);
