export default [
  "Zig Zag",
  "Williams Fractal",
  "Williams Alligator",
  "VWMA",
  "VWAP",
  "Volatility Index",
  "Typical Price",
  "Triple EMA",
  "SuperTrend",
  "Standard Error Bands",
  "Smoothed Moving Average",
  "Price Channel",
  "Parabolic SAR",
  "Moving Average Weighted",
  "Moving Average Triple",
  "Moving Average Multiple",
  "Moving Average Hamming",
  "Moving Average Exponential",
  "Moving Average Double",
  "Moving Average Channel",
  "Moving Average Adaptive",
  "Moving Average",
  "Median Price",
  "McGinley Dynamic",
  "MA with EMA Cross",
  "MA Cross",
  "Linear Regression Curve",
  "Least Squares Moving Average",
  "Keltner Channels",
  "Ichimoku Cloud",
  "Hull Moving Average",
  "Guppy Multiple Moving Average",
  "Envelopes",
  "EMA Cross",
  "Double EMA",
  "Donchian Channels",
  "Chande Kroll Stop",
  "Bollinger Bands",
  "Average Price",
  "Arnaud Legoux Moving Average",
  "Volume Profile Visible Range",
  "Accelerator Oscillator",
  "Accumulation/Distribution",
  "Accumulative Swing Index",
  "Advance/Decline",
  "Aroon",
  "Average Directional Index",
  "Average True Range",
  "Awesome Oscillator",
  "Balance of Power",
  "Bollinger Bands %B",
  "Bollinger Bands Width",
  "Chaikin Money Flow",
  "Chaikin Oscillator",
  "Chaikin Volatility",
  "Chop Zone",
  "Commodity Channel Index",
  "Connors RSI",
  "Coppock Curve",
  "Chande Momentum Oscillator",
  "Choppiness Index",
  "Detrended Price Oscillator",
  "Directional Movement",
  "Ease Of Movement",
  "Elder's Force Index",
  "Fisher Transform",
  "Historical Volatility",
  "Klinger Oscillator",
  "Know Sure Thing",
  "Linear Regression Slope",
  "MACD",
  "Majority Rule",
  "Mass Index",
  "Momentum",
  "Money Flow Index",
  "Net Volume",
  "On Balance Volume",
  "Price Oscillator",
  "Price Volume Trend",
  "Rate Of Change",
  "Relative Strength Index",
  "Relative Vigor Index",
  "Relative Volatility Index",
  "SMI Ergodic Indicator/Oscillator",
  "Standard Deviation",
  "Standard Error",
  "Stochastic",
  "Stochastic RSI",
  "Trend Strength Index",
  "TRIX",
  "True Strength Index",
  "Ultimate Oscillator",
  "Volatility Close-to-Close",
  "Volatility O-H-L-C",
  "Volatility Zero Trend Close-to-Close",
  "Volume Oscillator",
  "Vortex Indicator",
  "Williams %R",
];
