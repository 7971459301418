import React, { useState } from "react";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { useAuth } from "../../contexts/Auth";
import "./SignIn.scss";
import logo from "./logoTa.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useMedia } from "react-media";
import appleicon from "./Apple.png";
import googleicon from "./Google.png";
import windowsicon from "./Microsoft.png";
import { IconButton, Tooltip } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
const url =
  process.env["REACT_APP_RELEASE_STAGE"] === "staging"
    ? "https://stag-frontend.tradealgo.com"
    : "https://tradealgo.com";
const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const { signIn, signInLoading, signInWithProvider } = useAuth();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  function onSubmit() {
    signIn(values);
  }
  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };
  const DividerWithText = ({ text }) => (
    <div className="divider-container">
      <div className="divider-border" />
      <span className="divider-text">{text}</span>
      <div className="divider-border" />
    </div>
  );

  return (
    <>
      <a style={{ cursor: "pointer" }} href={url}>
        <img className="logo-ta" alt="" height={36} src={logo} />
      </a>
      <div className="sign_in__page">
        <div className="right-part">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo-image" />
          </div>
          <div className="big-caption">Log In</div>
          <div className={"contact-form"}>
            <div className={"input-form"}>
              <div className={"input-rows"}>
                <form onKeyDown={onKeyDownHandler} onSubmit={onSubmit}>
                  <div className={"input-row"}>
                    <input
                      value={values.email}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          email: e.target.value.replace(/\s+/g, ""),
                        })
                      }
                      autoComplete="username"
                      type={"text"}
                      className={"input-field full-width"}
                      placeholder={"Name or E-mail"}
                    />
                  </div>
                  <div className="input-row">
                    <div className="input-container">
                      <input
                        value={values.password}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            password: e.target.value,
                          })
                        }
                        type={showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        className="input-field full-width"
                        placeholder="Password"
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        className="password-toggle-icon"
                        aria-label="Toggle password visibility"
                      >
                        <Tooltip
                          placement="top"
                          title={(showPassword ? "Hide" : "Show") + " Password"}
                        >
                          {showPassword ? (
                            <VisibilityOffIcon sx={{ width: 29, height: 29 }} />
                          ) : (
                            <VisibilityIcon sx={{ width: 29, height: 29 }} />
                          )}
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                  <div className={isMobile ? "input-row2" : "input-row"}>
                    <div className={isMobile ? "squaredThree" : "squaredTwo"}>
                      <input
                        type="checkbox"
                        className={"remember-me-checkbox"}
                        id="rememberMe"
                        name="rememberMe"
                      />
                      <label htmlFor="rememberMe" className={"mb-0"}>
                        Remember me
                      </label>
                    </div>
                    <Link
                      to={`/reset-password${values.email.length > 0 ? `?email=${values.email}` : ""}`}
                      style={{ marginBottom: isMobile ? "10px" : "0px" }}
                    >
                      <span className={"forgot-password"}>
                        Forgot Password?
                      </span>
                    </Link>
                  </div>
                  <div className={"input-row2"}>
                    <Button
                      label="Log in"
                      className="ml-0 full-width btn-primary sign-in-button"
                      labelClassname="text-bold text-uppercase"
                      onClick={onSubmit}
                      disabled={signInLoading}
                    >
                      {signInLoading ? (
                        <CircularProgress color="inherit" size={24} />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <a style={{ textAlign: "center" }} href={url}>
                      <KeyboardBackspaceIcon />
                      &nbsp;&nbsp; Back To Homepage{" "}
                    </a>
                  </div>
                </form>
                <DividerWithText text="Or log in with" />
                <div className="social-container">
                  <IconButton
                    onClick={() => signInWithProvider("google")}
                    aria-label="Log in with Google"
                  >
                    <img src={googleicon} alt="Log in with Google" />
                  </IconButton>
                  <IconButton
                    onClick={() => signInWithProvider("microsoft")}
                    className="!relative !top[-1px]"
                    aria-label="Log in with Microsoft"
                  >
                    <img src={windowsicon} alt="Log in with Microsoft" />
                  </IconButton>
                  <IconButton
                    onClick={() => signInWithProvider("apple")}
                    aria-label="Log in with Apple"
                  >
                    <img src={appleicon} alt="Log in with Apple" />
                  </IconButton>
                </div>
                {/* <div className="signup-suggestion">
                  <span>Don't have an account? </span>
                  <Link to="/signup" className="signup-link">Sign up here</Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
