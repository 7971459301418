import React, { useMemo, useEffect, useState } from "react";

import "./WSPortfolioItem.scss";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { IconButton, Tooltip } from "@mui/material";
import { connect } from "react-redux";
import BeautifulSkeleton from "../../../common/Skeletons/BeautifulSkeleton";
import { dummyOptions } from "../../../TradingView/helpers";
import SparkLines from "../../../common/SparkLines";
import { useHistory, useLocation } from "react-router-dom";
import { init_portfolio } from "../../../../appRedux/ducks/wealthPortfolio";
import moment from "moment";
import FallbackTickerIcon from "../../../common/FallbackTickerIcon/FallbackTickerIcon";
import { portfolioSubscriptionRequest } from "../../../../appRedux/ducks/portfolioSubscription";
import CssPop from "../../../common/Tables/animations/CssPop";
import { useAuth } from "../../../../contexts/Auth";

const WSPortfolioItem = ({
  portfolio = {},
  type = "",
  wealthPortfolios = {},
  loadingPortfolio = false,
  handleSubscription = () => {},
  subscribedPortfolios = [],
  loadingSub = false,
}) => {
  const location = useLocation();
  const [isBlurred, setIsBlurred] = useState(false);
  const { isDemo } = useAuth();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setIsBlurred(searchParams.get("blurred") === "true" || isDemo);
  }, [location, isDemo]);

  const history = useHistory();
  const portfolioName = portfolio.url || "";

  const sparklineData = useMemo(() => {
    const portfolioData = wealthPortfolios[portfolioName] || init_portfolio;
    return (
      portfolioData?.sparkline?.map((el) => ({
        x: moment(el.created_at).valueOf(),
        y: Number(el.total_value).toFixed(2),
      })) || []
    );
  }, [wealthPortfolios, portfolioName]);

  const allocations = useMemo(() => {
    const portfolioData = wealthPortfolios[portfolioName] || init_portfolio;
    return (
      portfolioData.allocations
        ?.sort((a, b) => b?.ticker_weightage - a?.ticker_weightage)
        ?.slice(0, 5) || []
    );
  }, [wealthPortfolios, portfolioName]);

  const growth = useMemo(() => {
    const portfolioData = wealthPortfolios[portfolioName] || init_portfolio;
    return portfolioData?.growth?.find((el) => el.period === "1y")?.growth || 0;
  }, [wealthPortfolios, portfolioName]);

  const goPortfolioDetailPage = (type, url) => {
    if (type && url) {
      history.push(`/wealth-series/${type}/${url}${location.search}`);
    }
  };

  const updatedOptions = useMemo(() => {
    if (sparklineData?.length) {
      const isUpwardTrend =
        sparklineData.length > 1 &&
        Number(sparklineData[0].y) <
          Number(sparklineData[sparklineData.length - 1].y);
      const baseValue = Number(sparklineData[0].y);
      const earliestDate = sparklineData[0].x;
      const cutoffDate = moment("2024-12-23").valueOf();

      return {
        ...dummyOptions,
        chart: {
          ...dummyOptions.chart,
          height: 60,
        },
        tooltip: {
          ...dummyOptions.tooltip,
          x: {
            formatter: (val) => moment.utc(val).format("DD MMM YYYY HH:mm"),
          },
          y: {
            formatter: (_, { dataPointIndex }) => {
              const currentValue = Number(sparklineData[dataPointIndex].y);
              const percentageIncrease = (
                ((currentValue - baseValue) / baseValue) *
                100
              ).toFixed(2);
              return `<span style='font-size: 14px;'>${percentageIncrease}%</span>`;
            },
            title: {
              formatter: () => "",
            },
          },
        },
        colors: [isUpwardTrend ? "#5EEECD" : "#F32228"],
        annotations: {
          xaxis: [
            {
              x: earliestDate,
              x2: cutoffDate,
              fillColor: "#ECECEC",
              opacity: 0.4,
              label: {
                borderColor: "#ECECEC",
                style: {
                  color: "#333",
                  background: "#ECECEC",
                },
                text: "Simulated data",
              },
            },
          ],
        },
      };
    }
    return dummyOptions;
  }, [sparklineData]);

  const isSubscribed = subscribedPortfolios.includes(portfolio.id);

  return (
    <div
      className="ws-portfolio-item"
      onClick={() => goPortfolioDetailPage(type, portfolio.url)}
    >
      <div className={`ws-portfolio-item__header`}>
        <div
          className={`ws-portfolio-item__header-info ${type === "everest" ? "w-full" : ""}`}
        >
          <div className="ws-portfolio-item__title">
            {portfolio.name}
            <Tooltip
              placement="top"
              arrow
              title={
                isSubscribed ? "Unsubscribe from alerts" : "Get email alerts"
              }
            >
              <IconButton
                disabled={loadingSub}
                onClick={(event) => {
                  event.stopPropagation();
                  handleSubscription(portfolio.id, !isSubscribed);
                }}
              >
                {isSubscribed ? (
                  <MarkEmailReadIcon className="ws-portfolio-item__mail-icon" />
                ) : (
                  <MailOutlineIcon className="ws-portfolio-item__mail-icon" />
                )}
              </IconButton>
            </Tooltip>
          </div>
          <div className="ws-portfolio-item__performance">
            <span>Annual P/L*</span>
            {loadingPortfolio ? (
              <BeautifulSkeleton
                className="ws-portfolio-item__performance_value"
                width={40}
                height={20}
              />
            ) : (
              <span
                className={`ws-portfolio-item__performance_value ${growth >= 0 ? "positive" : "negative"}`}
              >
                {growth >= 0 ? "+" : ""}
                {growth.toFixed(2)}%
              </span>
            )}
          </div>
        </div>

        {type !== "everest" && (
          <div className="ws-portfolio-item__header-sparklink">
            {loadingPortfolio ? (
              <div className="main-loading-overlay">
                <div className="spinner"></div>
              </div>
            ) : (
              <SparkLines
                series={[
                  {
                    data: sparklineData,
                  },
                ]}
                options={updatedOptions}
                type={updatedOptions.chart.type}
                height={updatedOptions.chart.height}
                width={updatedOptions.chart.width}
                className="w-full"
              />
            )}
          </div>
        )}
      </div>
      <div className="ws-portfolio-item__content">
        <div className="ws-portfolio-holdings-label">Top 5 holdings</div>
        {(loadingPortfolio
          ? Array.from({ length: 5 }).fill(null)
          : allocations
        )?.map((holding, index) =>
          loadingPortfolio ? (
            <div key={index} className="ws-portfolio-holding">
              <div
                className={`ws-portfolio-holding__ticker ${isBlurred ? "blurred" : ""}`}
              >
                <BeautifulSkeleton variant="circular" width={22} height={22} />
                <BeautifulSkeleton width={50} height={20} />
              </div>
              {type !== "everest" && (
                <BeautifulSkeleton
                  className="ws-portfolio-holding__price"
                  width={20}
                  height={20}
                />
              )}
              <BeautifulSkeleton
                className="ws-portfolio-holding__percentage"
                width={20}
                height={20}
              />
            </div>
          ) : (
            <div key={index} className="ws-portfolio-holding">
              <div
                className={`ws-portfolio-holding__ticker ${isBlurred ? "blurred" : ""}`}
              >
                <FallbackTickerIcon
                  src={`https://assets.tradealgo.com/stock-logos/${holding.ticker}.png`}
                  ticker={holding.ticker}
                  className="ws-portfolio-holding__icon"
                />
                {holding.ticker}
              </div>
              {type !== "everest" && (
                <div className="ws-portfolio-holding__price">
                  <CssPop comparator={holding.price} ticker={holding.ticker} />
                </div>
              )}
              <div className="ws-portfolio-holding__percentage">
                {(holding.ticker_weightage * 100)?.toFixed(2)}%
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  wealthPortfolios: state.wealthPortfolio,
  loadingPortfolio: state.wealthPortfolio.loading,
  loadingSub: state.portfolioSubscribe.loading,
  subscribedPortfolios: state.portfolioSubscribe.subscribedPortfolios,
});

const dispatchToProps = (dispatch) => ({
  handleSubscription: (id, subscribe) =>
    dispatch(portfolioSubscriptionRequest(id, subscribe)),
});

export default connect(stateToProps, dispatchToProps)(WSPortfolioItem);
