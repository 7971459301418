import { all, put, takeEvery, fork, call } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
import { reversePortfolioHashMap } from "../../components/WealthSeriesPortfolioPage/components/WealthSeriesPortfolioTickers/tickerData";
export const init_portfolio = {
  portfolio_id: "",
  name: "",
  description: "",
  tier: "",
  strategy: "",
  theme_weightage: {},
  rebalance_id: "",
  validity: "",
  created_at: "",
  updated_at: "",
  allocations: [],
  growth: [],
  adjustments: [],
  sparkline: [], // Added sparkline to the initial portfolio structure
};
// Initial State
const INIT_STATE = {
  portfolio: init_portfolio,
  loading: true,
  error: null,
};

// Action Types
export const PORTFOLIO_LOAD = "PORTFOLIO/LOAD";
export const PORTFOLIO_LOAD_SUCCESS = "PORTFOLIO/LOAD/SUCCESS";
export const PORTFOLIO_LOAD_FAILURE = "PORTFOLIO/LOAD/FAILURE";
export const PORTFOLIO_ADJUSTMENTS_LOAD_SUCCESS =
  "PORTFOLIO/ADJUSTMENTS/LOAD/SUCCESS"; // New action type for adjustments

// Action Creators
export const portfolioLoad = (portfolioId, addSparkline = false) => ({
  type: PORTFOLIO_LOAD,
  portfolioId,
  addSparkline,
});

export const portfolioLoadSuccess = (data, sparklineData = []) => ({
  type: PORTFOLIO_LOAD_SUCCESS,
  data,
  sparklineData, // Include sparkline data in the success action
});

export const portfolioLoadFailure = (error) => ({
  type: PORTFOLIO_LOAD_FAILURE,
  error,
});

export const portfolioAdjustmentsLoadSuccess = (adjustments, id) => ({
  // New action creator for adjustments
  type: PORTFOLIO_ADJUSTMENTS_LOAD_SUCCESS,
  adjustments,
  id,
});

// Sagas
function* fetchPortfolio(action) {
  const { portfolioId, addSparkline = false } = action;
  try {
    const { data } = yield axios.get(
      `${apiURL}/wealth-series/portfolios/${portfolioId}`,
      { withCredentials: true },
    );
    const adjustmentsURL = `${apiURL}/wealth-series/portfolios/${portfolioId}/adjustments`;
    const adjustmentsResponse = yield call(axios.get, adjustmentsURL, {
      withCredentials: true,
    });
    let sparklineData = [];
    if (addSparkline) {
      const sparklineResponse = yield axios.get(
        `${apiURL}/wealth-series/portfolios/${portfolioId}/performance?period=1y`,
        { withCredentials: true },
      );
      sparklineData = sparklineResponse?.data?.performance?.records || []; // Assign the response data to sparklineData
    }
    yield put(
      portfolioLoadSuccess(
        {
          portfolio: data,
          loading: false,
        },
        sparklineData,
      ), // Pass sparkline data to the success action
    );

    // Dispatch adjustments load success
    yield put(
      portfolioAdjustmentsLoadSuccess(adjustmentsResponse.data, portfolioId),
    );
  } catch (error) {
    yield put(portfolioLoadFailure(error));
  }
}

function* listenPortfolioLoad() {
  yield takeEvery(PORTFOLIO_LOAD, fetchPortfolio);
}

// Root Saga
export function* saga() {
  yield all([fork(listenPortfolioLoad)]);
}

// Reducer
const reducer = (state = INIT_STATE, action) => {
  let id, portfolioName, newState, portfolioId, portName; // Declare variables outside the switch statement
  switch (action.type) {
    case PORTFOLIO_LOAD_SUCCESS:
      id = action.data.portfolio.portfolio_id;
      portfolioName = reversePortfolioHashMap[id];
      newState = {
        ...state,
        loading: action.data.loading,
        error: null,
      };
      if (portfolioName && !action.data.loading) {
        newState[portfolioName] = {
          ...action.data.portfolio,
          sparkline: action.sparklineData, // Add sparkline data to the portfolio
        };
      }
      return newState;
    case PORTFOLIO_ADJUSTMENTS_LOAD_SUCCESS:
      portfolioId = action.id;
      portName = reversePortfolioHashMap[portfolioId];
      return {
        ...state,
        [portName]: {
          ...state[portName],
          adjustments: action.adjustments,
        },
      };
    case PORTFOLIO_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
