import React, { useEffect, useMemo, useState } from "react";

import "./WealthSeriesPortfolioTickers.scss";
import { connect } from "react-redux";
import CssPop from "../../../common/Tables/animations/CssPop";
import { portfolioSymbolSetter } from "../../../../appRedux/ducks/portfolioTicker";
import BeautifulSkeleton from "../../../common/Skeletons/BeautifulSkeleton";
import { useParams, useLocation } from "react-router-dom";
import { init_portfolio } from "../../../../appRedux/ducks/wealthPortfolio";
import FallbackTickerIcon from "../../../common/FallbackTickerIcon/FallbackTickerIcon";
import { dxFeedSocketListen } from "../../../../appRedux/ducks/dxFeedSocket";
import { useAuth } from "../../../../contexts/Auth";

const WealthSeriesPortfolioTickers = ({
  wealthPortfolios,
  loadingPortfolio,
  setTicker,
  portfolioTicker,
  listen,
}) => {
  const { portfolio } = useParams();
  const wealthPortfolio = wealthPortfolios[portfolio] || init_portfolio;
  const tickerData = wealthPortfolio.allocations;
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const { isDemo } = useAuth();
  const [isBlurred, setIsBlurred] = useState(false);
  useEffect(() => {
    setIsBlurred(queryParams.get("blurred") === "true" || isDemo);
  }, [queryParams, isDemo]);
  const ticker = queryParams?.get("ticker");
  const sortedTickerData = useMemo(() => {
    // sort tickerData by quantity in descending order
    const sorted = tickerData.sort(
      (a, b) => b?.ticker_weightage - a?.ticker_weightage,
    );
    if (sorted[0] && !ticker) {
      setTicker(sorted[0].ticker);
    }
    return sorted;
  }, [tickerData, ticker]);

  const tickers = useMemo(() => {
    return tickerData.map((item) => item.ticker);
  }, [tickerData]);

  useEffect(() => {
    listen([...new Set(tickers)]);
    return () => listen([]);
  }, [tickers]);

  const handleTickerClick = (ticker) => {
    setTicker(ticker);
  };

  return (
    <div className="ws-portfolio-tickers">
      <div className="ws-portfolio-tickers__header">
        <div className="ws-portfolio-tickers__header__cell">Ticker</div>
        <div className="ws-portfolio-tickers__header__cell text-right">
          Price
        </div>
        <div className="ws-portfolio-tickers__header__cell text-right">
          Qty (%)
        </div>
      </div>

      <div className="ws-portfolio-tickers__body ws-scroll-element">
        {(loadingPortfolio
          ? Array.from({ length: 13 }).fill(null)
          : sortedTickerData
        )?.map((item, index) =>
          loadingPortfolio ? (
            <div className="ws-portfolio-tickers__body__row" key={index}>
              <div className="ws-portfolio-tickers__body__row__cell ws-ticker-data">
                <BeautifulSkeleton variant="circular" width={24} height={24} />
                <BeautifulSkeleton width={50} height={32} />
              </div>
              <BeautifulSkeleton
                className="ws-portfolio-tickers__body__row__cell text-right"
                width={90}
                height={32}
              />
              <BeautifulSkeleton
                className="ws-portfolio-tickers__body__row__cell text-right"
                width={90}
                height={32}
              />
            </div>
          ) : (
            <div
              key={index}
              className={`ws-portfolio-tickers__body__row ${item.ticker === portfolioTicker ? "active" : ""}`}
              onClick={() => handleTickerClick(item.ticker)}
            >
              <div
                className={`ws-portfolio-tickers__body__row__cell ws-ticker-data ${isBlurred ? "blurred" : ""}`}
              >
                <FallbackTickerIcon
                  src={`https://assets.tradealgo.com/stock-logos/${item.ticker}.png`}
                  ticker={item.ticker}
                  className="ws-portfolio-ticker-logo"
                />
                {item.ticker}
              </div>
              <div
                className={`ws-portfolio-tickers__body__row__cell text-right ${isBlurred ? "blurred" : ""}`}
              >
                <CssPop
                  dollar
                  ticker={item.ticker}
                  comparator={Number(item.price)}
                />
              </div>
              <div className="ws-portfolio-tickers__body__row__cell text-right">
                {(item.ticker_weightage * 100).toFixed(2)}%
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
};
const stateToProps = (state) => ({
  wealthPortfolios: state.wealthPortfolio,
  loadingPortfolio: state.wealthPortfolio.loading,
  portfolioTicker: state.portfolioTicker.symbol,
});

const dispatchToProps = (dispatch) => ({
  setTicker: (sym) => dispatch(portfolioSymbolSetter(sym)),
  listen: (symbols, options, disconnect, risk) =>
    dispatch(dxFeedSocketListen(symbols, options, disconnect, risk)),
});

export default connect(
  stateToProps,
  dispatchToProps,
)(WealthSeriesPortfolioTickers);
