import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
import { USER_SETTINGS_UPDATED } from "./userSettings";
// Action Types
export const UPDATE_FIELDS_LOAD = "UF/LOAD/TOP";
export const UPDATE_FIELDS_LOAD_SUCCESS = "UF/LOAD/TOP/SUCCESS";
export const UPDATE_FIELDS_LOAD_FAILURE = "UF/LOAD/TOP/FAILURE";

// Action Creators
export const updateFieldsLoad = (fields) => ({
  type: UPDATE_FIELDS_LOAD,
  fields,
});
export const updateFieldsLoadSuccess = (data) => ({
  type: UPDATE_FIELDS_LOAD_SUCCESS,
  data,
});
export const updateFieldsLoadFailure = (error) => ({
  type: UPDATE_FIELDS_LOAD_FAILURE,
  error,
});

// Sagas
function* updateFields(action) {
  try {
    const { fields } = action;
    const response = yield axios.put(
      `${apiURL}/user/fields`,
      { ...fields },
      { withCredentials: true },
    );
    if (response.status === 200) {
      yield put({ type: USER_SETTINGS_UPDATED });
    }
    yield put(updateFieldsLoadSuccess(response.data));
  } catch (error) {
    yield put(updateFieldsLoadFailure(error));
  }
}

function* listenUpdateFieldsLoad() {
  yield takeEvery(UPDATE_FIELDS_LOAD, updateFields);
}

// Root Saga
export function* saga() {
  yield all([fork(listenUpdateFieldsLoad)]);
}

const INIT_STATE = {
  fields: {},
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_FIELDS_LOAD_SUCCESS:
      return {
        ...state,
        fields: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
