import React, { useMemo, useState } from "react";

import "./WSBookMentorship.scss";
import { useAuth } from "../../../../contexts/Auth";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { updateFieldsLoad } from "../../../../appRedux/ducks/updateFields";
import { useSnackbar } from "notistack";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const WSBookMentorship = ({ tier, themeMode, setUserFields }) => {
  const { userData, sessionsLeftWealth, mentorshipSessionsUsed } = useAuth();
  const { series } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [mentor, setMentor] = useState("mike");

  const hasSessions = useMemo(() => {
    return sessionsLeftWealth[series];
  }, [series, sessionsLeftWealth]);

  useCalendlyEventListener({
    onEventScheduled: () => {
      setUserFields({
        training_sessions_used: {
          ...mentorshipSessionsUsed,
          [`wealth_mentorship_${series}`]:
            (mentorshipSessionsUsed[`wealth_mentorship_${series}`] || 0) + 1,
        },
      });
      enqueueSnackbar(
        "Your session has been booked, please check your email for confirmation!",
        {
          variant: "success",
        },
      );
    },
  });

  const handleChange = (event) => {
    setMentor(event.target.value);
  };

  const link = useMemo(() => {
    if (mentor === "dane") {
      if (tier === "atlas") {
        return "https://calendly.com/analysts-4/wealth-series-mentorship-atlas-dane";
      } else if (tier === "titan") {
        return "https://calendly.com/analysts-4/wealth-series-mentorship-titan-dane";
      } else if (tier === "everest") {
        return "https://calendly.com/analysts-4/wealth-series-mentorship-everest-dane";
      }
    } else {
      if (tier === "atlas") {
        return "https://calendly.com/analysts-4/wealth-series-mentorship-atlas-mike";
      } else if (tier === "titan") {
        return "https://calendly.com/analysts-4/wealth-series-mentorship-titan-mike";
      } else if (tier === "everest") {
        return "https://calendly.com/analysts-4/wealth-series-everest-mike";
      }
    }
  }, [tier, mentor]);

  return (
    <div
      className={`ws-book-mentorship ${themeMode === "light" ? "ws-light-calendar" : "ws-dark-calendar"}`}
    >
      {!!hasSessions && (
        <div className="ws-book-mentorship__selector">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select Your Mentor
            </InputLabel>
            <Select
              value={mentor}
              label="Select Your Mentor"
              onChange={handleChange}
            >
              <MenuItem value={"mike"}>Mike Andrews</MenuItem>
              <MenuItem value={"dane"}>Dane Glisek</MenuItem>
            </Select>
          </FormControl>
        </div>
      )}

      {hasSessions ? (
        <InlineWidget
          prefill={{
            email: userData?.email || "",
            name:
              (userData?.firstName || "") + " " + (userData?.lastName || ""),
          }}
          url={link}
        />
      ) : (
        <b>No More Sessions</b>
      )}
    </div>
  );
};

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setUserFields: (fields) => dispatch(updateFieldsLoad(fields)),
});

export default connect(stateToProps, dispatchToProps)(WSBookMentorship);
