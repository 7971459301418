import { all, put, takeEvery, fork } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
import { showSnackbar } from "./snackbar";
import { idToFullNameMap } from "../../components/WealthSeriesOverview/portfoliosData";

// Action Types for POST Subscription handling
export const PORTFOLIO_SUBSCRIPTION_REQUEST = "PORTFOLIO/SUBSCRIPTION/REQUEST";
export const PORTFOLIO_SUBSCRIPTION_SUCCESS = "PORTFOLIO/SUBSCRIPTION/SUCCESS";
export const PORTFOLIO_SUBSCRIPTION_FAILURE = "PORTFOLIO/SUBSCRIPTION/FAILURE";
export const PORTFOLIO_SUBSCRIPTION_LOADING = "PORTFOLIO/SUBSCRIPTION/LOADING";

// Action Types for GET Subscribed Portfolios
export const FETCH_SUBSCRIBED_PORTFOLIOS_REQUEST =
  "FETCH/SUBSCRIBED/PORTFOLIOS/REQUEST";
export const FETCH_SUBSCRIBED_PORTFOLIOS_SUCCESS =
  "FETCH/SUBSCRIBED/PORTFOLIOS/SUCCESS";
export const FETCH_SUBSCRIBED_PORTFOLIOS_FAILURE =
  "FETCH/SUBSCRIBED/PORTFOLIOS/FAILURE";
export const FETCH_SUBSCRIBED_PORTFOLIOS_LOADING =
  "FETCH/SUBSCRIBED/PORTFOLIOS/LOADING";

// Action Types for triggering refetch on POST (similar to user settings cases)
export const TRIGGER_FETCH_SUBSCRIBED_PORTFOLIOS =
  "TRIGGER/FETCH/SUBSCRIBED/PORTFOLIOS";
export const RESET_FETCH_SUBSCRIBED_PORTFOLIOS =
  "RESET/FETCH/SUBSCRIBED/PORTFOLIOS";

// Action Creators for POST Subscription handling
export const portfolioSubscriptionRequest = (portfolio_id, subscribe) => ({
  type: PORTFOLIO_SUBSCRIPTION_REQUEST,
  portfolio_id,
  subscribe,
});
export const portfolioSubscriptionSuccess = (data) => ({
  type: PORTFOLIO_SUBSCRIPTION_SUCCESS,
  data,
});
export const portfolioSubscriptionFailure = (error) => ({
  type: PORTFOLIO_SUBSCRIPTION_FAILURE,
  error,
});
export const portfolioSubscriptionLoading = () => ({
  type: PORTFOLIO_SUBSCRIPTION_LOADING,
});

// Action Creators for GET Subscribed Portfolios
export const fetchSubscribedPortfoliosRequest = () => ({
  type: FETCH_SUBSCRIBED_PORTFOLIOS_REQUEST,
});
export const fetchSubscribedPortfoliosSuccess = (data) => ({
  type: FETCH_SUBSCRIBED_PORTFOLIOS_SUCCESS,
  data,
});
export const fetchSubscribedPortfoliosFailure = (error) => ({
  type: FETCH_SUBSCRIBED_PORTFOLIOS_FAILURE,
  error,
});
export const fetchSubscribedPortfoliosLoading = () => ({
  type: FETCH_SUBSCRIBED_PORTFOLIOS_LOADING,
});

// Action Creators for triggering refetch
export const triggerFetchSubscribedPortfolios = () => ({
  type: TRIGGER_FETCH_SUBSCRIBED_PORTFOLIOS,
});
export const resetFetchSubscribedPortfolios = () => ({
  type: RESET_FETCH_SUBSCRIBED_PORTFOLIOS,
});

// Sagas
function* handlePortfolioSubscription(action) {
  try {
    yield put(portfolioSubscriptionLoading());
    const { portfolio_id, subscribe } = action;
    const actionType = subscribe ? "subscribe" : "unsubscribe";
    const endpoint = `${apiURL}/wealth-series/portfolios/${portfolio_id}/notifications/${actionType}`;
    const { status } = yield axios.post(
      endpoint,
      {},
      { withCredentials: true },
    );
    yield put(portfolioSubscriptionSuccess(status));
    // After successful subscription change, trigger refetch of subscribed portfolios
    if (status === 200) {
      yield put({ type: FETCH_SUBSCRIBED_PORTFOLIOS_REQUEST });
      yield put(
        showSnackbar(
          `Successfully ${actionType}d ${subscribe ? "to" : "from"} ${idToFullNameMap[portfolio_id]} email alerts!`,
          { variant: "success" },
        ),
      );
    }
  } catch (error) {
    yield put(portfolioSubscriptionFailure(error));
  }
}

function* handleFetchSubscribedPortfolios() {
  try {
    yield put(fetchSubscribedPortfoliosLoading());
    const { data } = yield axios.get(
      `${apiURL}/wealth-series/user/subscribed-portfolios`,
      { withCredentials: true },
    );

    yield put(fetchSubscribedPortfoliosSuccess(data));
  } catch (error) {
    yield put(fetchSubscribedPortfoliosFailure(error));
  }
}

function* listenPortfolioSubscription() {
  yield takeEvery(PORTFOLIO_SUBSCRIPTION_REQUEST, handlePortfolioSubscription);
}

function* listenFetchSubscribedPortfolios() {
  yield takeEvery(
    FETCH_SUBSCRIBED_PORTFOLIOS_REQUEST,
    handleFetchSubscribedPortfolios,
  );
}

// Root Saga
export function* saga() {
  yield all([
    fork(listenPortfolioSubscription),
    fork(listenFetchSubscribedPortfolios),
  ]);
}

// Initial State
const INIT_STATE = {
  loading: false,
  status: "",
  error: null,
  subscribedPortfolios: [],
  triggerFetchSubscribedPortfolios: false,
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PORTFOLIO_SUBSCRIPTION_LOADING:
    case FETCH_SUBSCRIBED_PORTFOLIOS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PORTFOLIO_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        status: action.data,
        error: null,
        loading: false,
      };
    case PORTFOLIO_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        status: "",
        error: action.error,
        loading: false,
      };

    case FETCH_SUBSCRIBED_PORTFOLIOS_SUCCESS:
      return {
        ...state,
        subscribedPortfolios: action.data,
        error: null,
        loading: false,
        triggerFetchSubscribedPortfolios: false, // reset trigger after successful fetch
      };
    case FETCH_SUBSCRIBED_PORTFOLIOS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        triggerFetchSubscribedPortfolios: false,
      };

    case TRIGGER_FETCH_SUBSCRIBED_PORTFOLIOS:
      return {
        ...state,
        triggerFetchSubscribedPortfolios: true,
      };
    case RESET_FETCH_SUBSCRIBED_PORTFOLIOS:
      return {
        ...state,
        triggerFetchSubscribedPortfolios: false,
      };

    default:
      return state;
  }
};

export default reducer;
