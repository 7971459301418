import "./WSPortfolioActivities.scss";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import moment from "moment";
import BeautifulSkeleton from "../../../common/Skeletons/BeautifulSkeleton";
import FallbackTickerIcon from "../../../common/FallbackTickerIcon/FallbackTickerIcon";
import React, { useState, useMemo, useRef, useEffect } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useHistory, useParams } from "react-router-dom";
import SimpleDisplay from "../../../common/Tables/renderers/SimpleDisplay";
import { useAuth } from "../../../../contexts/Auth";

const WSPortfolioActivity = ({ item, loading }) => {
  const [isBlurred, setIsBlurred] = useState(false);
  const { isDemo } = useAuth();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setIsBlurred(searchParams.get("blurred") === "true" || isDemo);
  }, [location, isDemo]);
  const [open, setOpen] = useState(true);
  const { series } = useParams();
  const [sortConfig, setSortConfig] = useState({
    key: "diff",
    direction: "descending",
  });
  const history = useHistory();

  const activitiesRef = useRef(null);
  const headerRef = useRef(null);
  const isSyncingScroll = useRef(false);

  const sortedActivities = useMemo(() => {
    if (loading || !item.adjustments)
      return Array.from({ length: 5 }).fill(null);
    const sortableItems = [...(item.adjustments || [])];
    sortableItems.sort((a, b) => {
      if (a[sortConfig.key] === undefined || b[sortConfig.key] === undefined)
        return 0;
      if (sortConfig.key === "diff") {
        const aDiffAbs = Math.abs(a[sortConfig.key]);
        const bDiffAbs = Math.abs(b[sortConfig.key]);
        return sortConfig.direction === "ascending"
          ? aDiffAbs - bDiffAbs
          : bDiffAbs - aDiffAbs;
      } else if (["newPrice"].includes(sortConfig.key)) {
        return sortConfig.direction === "ascending"
          ? a[sortConfig.key] - b[sortConfig.key]
          : b[sortConfig.key] - a[sortConfig.key];
      } else if (sortConfig.key === "last_price") {
        const aLastPrice = parseFloat(a.last_price);
        const bLastPrice = parseFloat(b.last_price);
        return sortConfig.direction === "ascending"
          ? aLastPrice - bLastPrice
          : bLastPrice - aLastPrice;
      } else {
        const aKeyLower = a[sortConfig.key]?.toLowerCase() ?? "";
        const bKeyLower = b[sortConfig.key]?.toLowerCase() ?? "";
        if (aKeyLower < bKeyLower)
          return sortConfig.direction === "ascending" ? -1 : 1;
        if (aKeyLower > bKeyLower)
          return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortableItems;
  }, [item?.adjustments, sortConfig, loading]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };
  const navigateTicker = (portfolio, ticker) => {
    history.push(
      `/wealth-series/${series}/${portfolio}?ticker=${ticker}&blurred=${isBlurred}`,
    );
  };

  useEffect(() => {
    const handleScroll = (source, target) => {
      if (isSyncingScroll.current) return;
      isSyncingScroll.current = true;
      target.scrollLeft = source.scrollLeft;
      isSyncingScroll.current = false;
    };

    const headerElement = headerRef.current;
    const activitiesElement = activitiesRef.current;

    const onHeaderScroll = () => handleScroll(headerElement, activitiesElement);
    const onActivitiesScroll = () =>
      handleScroll(activitiesElement, headerElement);

    if (headerElement && activitiesElement) {
      headerElement.addEventListener("scroll", onHeaderScroll);
      activitiesElement.addEventListener("scroll", onActivitiesScroll);
    }

    return () => {
      if (headerElement) {
        headerElement.removeEventListener("scroll", onHeaderScroll);
      }
      if (activitiesElement) {
        activitiesElement.removeEventListener("scroll", onActivitiesScroll);
      }
    };
  }, []);

  return (
    <div className="ws-portfolio-activity">
      <div
        className={`ws-portfolio-activity__header ${open ? "" : "closed"}`}
        onClick={() => setOpen(!open)}
      >
        {loading ? (
          <div className="ws-portfolio-activity__date">
            <BeautifulSkeleton width={150} height={20} />
            <BeautifulSkeleton width={20} height={20} />
          </div>
        ) : (
          <div className="ws-portfolio-activity__date">
            {moment(item.date).format("dddd, MMM D, YYYY")}
            <span>{item?.adjustments?.length}</span>
          </div>
        )}
        {open ? <RemoveIcon /> : <AddIcon />}
      </div>

      <div>
        <div
          ref={headerRef}
          className={`ws-portfolio-activity__table_header ${open ? "open" : "closed"}`}
        >
          <div
            className="ws-portfolio-activity__activity-portfolio"
            onClick={() => requestSort("portfolioName")}
          >
            Portfolio{" "}
            {sortConfig.key === "portfolioName" &&
              (sortConfig.direction === "ascending" ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownIcon />
              ))}
          </div>
          <div
            className="ws-portfolio-activity__activity-ticker"
            onClick={() => requestSort("ticker")}
          >
            Ticker{" "}
            {sortConfig.key === "ticker" &&
              (sortConfig.direction === "ascending" ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownIcon />
              ))}
          </div>
          <div
            className="ws-portfolio-activity__activity-price"
            onClick={() => requestSort("last_price")}
          >
            Current Price{" "}
            {sortConfig.key === "last_price" &&
              (sortConfig.direction === "ascending" ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownIcon />
              ))}
          </div>
          <div
            className="ws-portfolio-activity__activity-change"
            onClick={() => requestSort("newPrice")}
          >
            Holding Change{" "}
            {sortConfig.key === "newPrice" &&
              (sortConfig.direction === "ascending" ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownIcon />
              ))}
          </div>
          <div
            className="ws-portfolio-activity__activity-impact"
            onClick={() => requestSort("diff")}
          >
            Impact{" "}
            {sortConfig.key === "diff" &&
              (sortConfig.direction === "ascending" ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownIcon />
              ))}
          </div>
        </div>

        <div
          ref={activitiesRef}
          className={`ws-portfolio-activity__activities ${open ? "open" : "closed"}`}
        >
          {(loading
            ? Array.from({ length: 5 }).fill(null)
            : sortedActivities
          )?.map((activity, index) =>
            loading ? (
              <div key={index} className="ws-portfolio-activity__activity">
                <div className="ws-portfolio-activity__activity-portfolio">
                  <BeautifulSkeleton width={100} height={32} />
                </div>
                <div className="ws-portfolio-activity__activity-ticker">
                  <span className="ws-portfolio-activity__activity-ticker-icon">
                    <BeautifulSkeleton
                      variant="circular"
                      width={24}
                      height={24}
                    />
                    <BeautifulSkeleton
                      width={70}
                      height={32}
                      className="hidden-on-mobile"
                    />
                  </span>
                  <span className="ws-portfolio-activity__company hidden-on-mobile">
                    <BeautifulSkeleton width={100} height={32} />
                  </span>
                </div>
                <div className="ws-portfolio-activity__activity-price">
                  <BeautifulSkeleton width={100} height={32} />
                </div>
                <div className="ws-portfolio-activity__activity-change">
                  <BeautifulSkeleton width={150} height={32} />
                </div>
                <div className={`ws-portfolio-activity__activity-impact`}>
                  <BeautifulSkeleton width={100} height={32} />
                </div>
              </div>
            ) : (
              <div key={index} className="ws-portfolio-activity__activity">
                <div
                  className={`ws-portfolio-activity__activity-portfolio ${isBlurred ? "blurred" : ""}`}
                >
                  {activity.portfolioName}
                </div>
                <div
                  className={`ws-portfolio-activity__activity-ticker ${isBlurred ? "blurred" : ""}`}
                  onClick={() =>
                    navigateTicker(activity.portfolioKey, activity.ticker)
                  }
                >
                  <span className="ws-portfolio-activity__activity-ticker-icon">
                    <FallbackTickerIcon
                      src={`https://assets.tradealgo.com/stock-logos/${activity.ticker}.png`}
                      ticker={activity.ticker}
                      className="ws-portfolio-activity__activity-icon"
                    />
                    {activity.ticker}
                  </span>
                  <span className="ws-portfolio-activity__company">
                    {activity.name}
                  </span>
                </div>
                <div
                  className={`ws-portfolio-activity__activity-price ${isBlurred ? "blurred" : ""}`}
                >
                  <SimpleDisplay
                    ticker={activity.ticker}
                    comparator={activity.last_price}
                  />
                </div>
                <div className="ws-portfolio-activity__activity-change">
                  <span
                    style={{ width: 50, textAlign: "right" }}
                    className="ws-portfolio-activity__activity-change--old"
                  >
                    {(activity.oldPrice * 100).toFixed(2)}%
                  </span>
                  <ArrowRightAltIcon className="ws-portfolio-activity__activity-change--icon" />
                  <span
                    style={{ width: 50, textAlign: "left" }}
                    className="ws-portfolio-activity__activity-change--new"
                  >
                    {(activity.newPrice * 100).toFixed(2)}%
                  </span>
                </div>
                <div className={`ws-portfolio-activity__activity-impact`}>
                  <span
                    className={`${(activity.diff * 100).toFixed(2) == 0 ? "ws-portfolio-activity__activity-impact--neutral" : activity.diff * 100 > 0 ? "ws-portfolio-activity__activity-impact--positive" : "ws-portfolio-activity__activity-impact--negative"}`}
                  >
                    {(activity.diff * 100).toFixed(2) == 0
                      ? "0.00"
                      : (activity.diff * 100).toFixed(2)}
                    %
                  </span>
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default WSPortfolioActivity;
