import { useEffect, useMemo, useState } from "react";

import "./FallbackTickerIcon.scss";

const FallbackTickerIcon = ({ src, ticker, className }) => {
  const [hasError, setHasError] = useState(false);

  // Reset hasError when src changes
  useEffect(() => {
    setHasError(false);
  }, [src]);

  const caption = useMemo(() => {
    // return first letter of ticker
    return ticker && ticker[0].toUpperCase();
  }, [ticker]);

  return hasError ? (
    <div className={`${className} fallback-ticker-empty-icon`}>{caption}</div>
  ) : (
    <img
      src={src}
      alt={ticker}
      className={className}
      onError={() => setHasError(true)}
    />
  );
};

export default FallbackTickerIcon;
