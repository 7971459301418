import { portfolioHashMap } from "../WealthSeriesPortfolioPage/components/WealthSeriesPortfolioTickers/tickerData";
export const idToFullNameMap = {
  [portfolioHashMap["atlas_growth"]]: "Atlas Accelerated Growth",
  [portfolioHashMap["atlas_low-risk-volatility"]]: "Atlas Secure Bluechip",
  [portfolioHashMap["atlas_fundamental"]]: "Atlas Undervalued Advantage",
  [portfolioHashMap["titan_growth"]]: "Titan Dynamic Upside",
  [portfolioHashMap["titan_low-risk-volatility"]]: "Titan Steady Guard",
  [portfolioHashMap["titan_fundamental"]]: "Titan Fundamental Edge",
  [portfolioHashMap["everest_growth"]]: "Everest Momentum Ascent",
  [portfolioHashMap["everest_low-risk-volatility"]]:
    "Everest Resilient Horizon",
  [portfolioHashMap["everest_fundamental"]]: "Everest Hidden Value Discovery",
  [portfolioHashMap["everest_defensive"]]: "Everest Capital Shield",
  [portfolioHashMap["everest_balanced"]]: "Everest Steady Climb",
};

export const portfoliosData = {
  atlas: {
    ids: [
      portfolioHashMap["atlas_growth"],
      portfolioHashMap["atlas_low-risk-volatility"],
      portfolioHashMap["atlas_fundamental"],
    ],
    portfolios: [
      {
        name: "Accelerated Growth",
        url: "atlas_growth",
        id: portfolioHashMap["atlas_growth"],
        performance: 11.8,
      },
      {
        name: "Secure Bluechip",
        url: "atlas_low-risk-volatility",
        id: portfolioHashMap["atlas_low-risk-volatility"],
        performance: -3.8,
      },
      {
        name: "Undervalued Advantage",
        url: "atlas_fundamental",
        id: portfolioHashMap["atlas_fundamental"],
        performance: 8.8,
      },
    ],
  },
  titan: {
    ids: [
      portfolioHashMap["titan_growth"],
      portfolioHashMap["titan_low-risk-volatility"],
      portfolioHashMap["titan_fundamental"],
    ],
    portfolios: [
      {
        name: "Dynamic Upside",
        url: "titan_growth",
        id: portfolioHashMap["titan_growth"],
        performance: 11.8,
      },
      {
        name: "Steady Guard",
        url: "titan_low-risk-volatility",
        id: portfolioHashMap["titan_low-risk-volatility"],
        performance: -3.8,
      },
      {
        name: "Fundamental Edge",
        url: "titan_fundamental",
        id: portfolioHashMap["titan_fundamental"],
        performance: 8.8,
      },
    ],
  },
  everest: {
    ids: [
      portfolioHashMap["everest_growth"],
      portfolioHashMap["everest_low-risk-volatility"],
      portfolioHashMap["everest_fundamental"],
      portfolioHashMap["everest_defensive"],
      portfolioHashMap["everest_balanced"],
    ],
    portfolios: [
      {
        name: "Momentum Ascent",
        url: "everest_growth",
        id: portfolioHashMap["everest_growth"],
        performance: 11.8,
      },
      {
        name: "Resilient Horizon",
        url: "everest_low-risk-volatility",
        id: portfolioHashMap["everest_low-risk-volatility"],
        performance: -3.8,
      },
      {
        name: "Hidden Value Discovery",
        url: "everest_fundamental",
        id: portfolioHashMap["everest_fundamental"],
        performance: 8.8,
      },
      {
        name: "Capital Shield",
        url: "everest_defensive",
        id: portfolioHashMap["everest_defensive"],
        performance: 8.8,
      },
      {
        name: "Steady Climb",
        url: "everest_balanced",
        id: portfolioHashMap["everest_balanced"],
        performance: 8.8,
      },
    ],
  },
};
export const urlToNameMap = {
  atlas_growth: "Accelerated Growth",
  "atlas_low-risk-volatility": "Secure Bluechip",
  atlas_fundamental: "Undervalued Advantage",
  titan_growth: "Dynamic Upside",
  "titan_low-risk-volatility": "Steady Guard",
  titan_fundamental: "Fundamental Edge",
  everest_growth: "Momentum Ascent",
  "everest_low-risk-volatility": "Resilient Horizon",
  everest_fundamental: "Hidden Value Discovery",
  everest_defensive: "Capital Shield",
  everest_balanced: "Steady Climb",
};
