import React from "react";

import "./WSTelegramCTA.scss";
import { Button } from "@mui/material";

const WSTelegramCTA = ({ link, className }) => {
  return (
    <div
      className={className ? className + " ws-telegram-cta" : "ws-telegram-cta"}
      onClick={() => window.open(link, "_blank")}
    >
      <Button
        variant="text"
        startIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clipPath="url(#clip0_22532_13295)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM13.7921 17.6835L16.8819 7.93891C17.0612 7.37328 16.5178 6.84569 15.9577 7.04172L6.45806 10.3666C5.87335 10.5712 5.84013 11.3856 6.40622 11.6372L9.86366 13.1739L12.2091 11.4985C12.3415 11.404 12.5046 11.5671 12.41 11.6994L10.7485 14.0256L12.5219 17.7696C12.7879 18.3311 13.6043 18.2758 13.7921 17.6835Z"
                fill="#007BFF"
              />
            </g>
            <defs>
              <clipPath id="clip0_22532_13295">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        }
      >
        Get Telegram Alerts
      </Button>
    </div>
  );
};

export default WSTelegramCTA;
