import React, { useEffect, useState, useRef, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import SearchBar from "../common/SearchBar";
import { makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import Logo from "../common/Logo";
import { LogoDarkIcon, LogoWhiteIcon } from "../common/Actions";
import "./topbar.scss";
import "../common/Layout/PrivateLayout/sidebarStyles.scss";
import { sidebarSetter } from "../../appRedux/ducks/sidebarOpen";
import { useMedia } from "react-media";
import VideoModal from "./VimeoModal";
import { tutorialVideoLookup } from "./tutorialMetadata";
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp";
// import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Gravatar from "react-gravatar";
import { useAuth } from "../../contexts/Auth";
import BeautifulSkeleton from "../common/Skeletons/BeautifulSkeleton";
import { LayoutSwitch } from "../common/Button/LayoutSwitch";
import { layoutSetter } from "../../appRedux/ducks/layoutMode";
import { findLongestName } from "./DashboardHeader";

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const useStyles = makeStyles((theme) => ({
  background: {
    zIndex: 100,
    width: "100%",
  },
  backgroundWithBanner: {
    zIndex: 100,
    width: "100%",
  },
  text: {
    color: theme.palette.primary.text,
  },
  appBanner: {
    fontSize: "16px",
    backgroundColor: "#000000 !important",
    color: "#fff",
    textAlign: "center",
    padding: "10px",
    position: "sticky",
    fontWeight: 500,
    top: 0,
  },
}));

const HomeHeader = ({
  setSidebar,
  name,
  themeMode,
  layoutMode,
  layoutToggle,
}) => {
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const [mobileSearch, setMobileSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const hardHistory = useHistory();
  const classes = useStyles();
  const [isClick, setClick] = useState(true);
  const headerDiv = useRef(null);
  const { userData, loading, canSeeInternal, user, hasWealth } = useAuth();
  const location = useLocation();

  let longestName = useMemo(
    () => findLongestName(userData, user),
    [userData, user],
  );
  useEffect(() => {
    setSidebar(isClick);
  }, [isClick]);

  let showLayoutToggle = useMemo(() => {
    return (
      location && location.pathname && location.pathname.indexOf("/home/") > -1
    );
  }, [location.pathname]);

  const logoUrl = useMemo(() => {
    return canSeeInternal ? "/home/Intraday/Auto/Up" : "/free-tier";
  }, [canSeeInternal]);

  const memberTitle = useMemo(() => {
    return canSeeInternal || hasWealth ? "Premium Member" : "Free Member";
  }, [canSeeInternal]);

  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    let classes = Array.from(body.classList);
    if (!classes.includes("sidebar-enable")) {
      body.classList.add("sidebar-enable");
      if (!isMobile) document.body.setAttribute("data-sidebar-size", "sm");
    } else {
      body.classList.remove("sidebar-enable");
      if (!isMobile) document.body.setAttribute("data-sidebar-size", "lg");
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openUserMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToURL = (url) => {
    hardHistory.push(url);
    handleClose();
  };

  const signOut = () => {
    handleClose();
    hardHistory.push("/signout");
  };

  useEffect(() => {
    if (!isMobile) {
      window.onscroll = () => {
        if (headerDiv != null && headerDiv.current != null) {
          if (window.pageYOffset > 30) {
            headerDiv.current.classList.add("header-bg-white");
          } else {
            headerDiv.current.classList.remove("header-bg-white");
          }
        }
      };
    }
  }, [isMobile]);
  const color = useMemo(() => {
    return themeMode === "dark" ? "white" : "black";
  }, [themeMode]);

  useEffect(() => {
    var body = document.body;
    if (isMobile == false) {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "lg");
      setClick(true);
    } else {
      body.classList.remove("sidebar-enable");
      document.body.removeAttribute("data-sidebar-size");
    }
  }, [isMobile]);

  const email = useMemo(() => {
    if (userData) {
      if (userData?.email === "lajollawomp@gmail.com") {
        return "";
      } else {
        return userData?.email || user?.email;
      }
    }
    return "";
  }, [userData?.email, user?.email]);

  if (isMobile)
    return (
      <>
        <VideoModal name={name} open={open} setOpen={setOpen} />
        <div
          className={clsx(
            classes.background,
            "d-flex w-100 align-items-center",
          )}
          ref={headerDiv}
        >
          <button
            onClick={() => {
              tToggle();
            }}
            type="button"
            className="btn btn-sm px-3 font-size-16 header-item"
            id="vertical-menu-btn"
          >
            <svg
              width="19"
              height="16"
              viewBox="0 0 19 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.634766 0V2.29555H18.9992V0H0.634766ZM0.634766 6.81779V9.11334H18.9992V6.81779H0.634766ZM0.634766 13.7044V16H18.9992V13.7044H0.634766Z"
                fill="#1C1C1E"
              />
            </svg>
          </button>
          <div
            className={`pb-8  w-100 d-flex ${mobileSearch ? "is-mobile-search" : "justify-between"} align-center`}
            style={{ paddingTop: 10 }}
          >
            <div className="mobile-header-title-search-bar">
              <>
                {!mobileSearch && (
                  <div className="">
                    <span
                      style={{
                        fontSize: isMobile ? "15px" : "24px",
                      }}
                      className={clsx(classes.text, "home-title")}
                    >
                      {name}
                      {tutorialVideoLookup[name] && (
                        <span className="joyride-helper">
                          <Tooltip
                            arrow={true}
                            title={
                              name === "SwingTrade AI"
                                ? "Our algorithms have identified these companies with great momentum and great fundamentals for potential swing trades. We recommend holding these assets for a minimum of 1 month and ideally between 6-12 months for best results. New picks are added every week as they are identified."
                                : `Click to view a tutorial video about ${name}.`
                            }
                          >
                            <ErrorOutlineSharpIcon
                              onClick={() => {
                                if (name !== "SwingTrade AI") {
                                  setOpen(true);
                                }
                              }}
                              style={{ marginLeft: "10px" }}
                            />
                          </Tooltip>
                        </span>
                      )}
                    </span>
                  </div>
                )}
              </>
              <div className="mobile-search-bar-box">
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  {mobileSearch ? (
                    <SearchBar
                      isMobile={isMobile}
                      setMobileSearch={setMobileSearch}
                      popupContainerId="header-search-bar"
                    />
                  ) : (
                    <svg
                      onClick={() => setMobileSearch(true)}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.4121 14.4121L20 20"
                        stroke="#A2A3A5"
                        strokeWidth="2"
                        strokeLinecap="square"
                      />
                      <path
                        d="M10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16Z"
                        stroke="#A2A3A5"
                        strokeWidth="2"
                        strokeLinecap="square"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>

            <div className="home-header-right-box">
              {/* <NotificationsIcon className="home-header-notification-icon"/> */}
              {showLayoutToggle && (
                <>
                  <div className="layout-toggle-element">
                    <span className="layout-toggle-element-label">
                      Layout: {layoutMode === "new" ? "New" : "Classic"}
                    </span>
                    <LayoutSwitch
                      checked={layoutMode === "new"}
                      onClick={() => {
                        layoutToggle(layoutMode === "new" ? "old" : "new");
                      }}
                    />
                  </div>
                  <div className="home-header-right-box-spliter" />
                </>
              )}
              {loading ? (
                <BeautifulSkeleton
                  variant="rectangular"
                  width={180}
                  height={48}
                />
              ) : (
                <div
                  className="home-header-right-box-user"
                  onClick={handleClick}
                >
                  {userData?.email && (
                    <Gravatar
                      email={email}
                      size={40}
                      className="home-header-user-avatar"
                    />
                  )}

                  <div className="header-profile-user-info">
                    <div className="header-profile-user-name">
                      {longestName}
                    </div>
                    <div className="header-profile-user-type">
                      {memberTitle}
                    </div>
                  </div>
                </div>
              )}

              <Menu
                className="home-header-user-menu"
                anchorEl={anchorEl}
                open={openUserMenu}
                onClose={handleClose}
              >
                <MenuItem
                  className={`home-header-user-menu-item ${hardHistory.location.pathname === "/settings/profile" ? "active" : ""}`}
                  onClick={() => goToURL("/settings/profile")}
                >
                  Edit Profile
                </MenuItem>
                <MenuItem
                  className={`home-header-user-menu-item ${hardHistory.location.pathname === "/settings/subscriptions" ? "active" : ""}`}
                  onClick={() => goToURL("/settings/subscriptions")}
                >
                  My Subscriptions
                </MenuItem>
                <MenuItem
                  className={`home-header-user-menu-item ${hardHistory.location.pathname === "/settings/notifications" ? "active" : ""}`}
                  onClick={() => goToURL("/settings/notifications")}
                >
                  Notifications
                </MenuItem>
                <MenuItem
                  className="home-header-user-menu-item"
                  onClick={signOut}
                >
                  Log Out
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </>
    );

  return (
    <div
      className={clsx(
        hardHistory.location &&
          hardHistory.location.pathname &&
          hardHistory.location.pathname === "/real-time-options-flow"
          ? classes.backgroundWithBanner
          : classes.background,
        "home-font-family py-3 header-height",
      )}
    >
      <VideoModal name={name} open={open} setOpen={setOpen} />
      <div className="container sidebar-content-container">
        <header id="page-topbar" className={`${themeMode}-mode`}>
          {/* <div className={classes.appBanner}>
          Own a piece of TradeAlgo! Invest before it’s too late => <a target="_blank" href="https://www.startengine.com/trade-algo/">StartEngine.com</a>
          </div> */}
          <div className="navbar-header">
            <div className="d-flex w-100">
              <div
                className={`navbar-brand-box ${themeMode === "dark" ? "dark-brandbox" : ""}`}
              >
                <Logo
                  url={logoUrl}
                  type="h1"
                  icon={
                    themeMode === "dark" ? (
                      <LogoWhiteIcon
                        style={{ width: "30px", height: "20px" }}
                      />
                    ) : (
                      <LogoDarkIcon style={{ width: "30px", height: "20px" }} />
                    )
                  }
                  from={"dashboard"}
                  header={true}
                  hide={!isClick && !isMobile}
                />

                <button
                  onClick={() => {
                    tToggle();
                  }}
                  type="button"
                  className="btn btn-sm header-item"
                  id="vertical-menu-btn"
                >
                  <svg
                    width="19"
                    height="16"
                    viewBox="0 0 19 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.634766 0V2.29555H18.9992V0H0.634766ZM0.634766 6.81779V9.11334H18.9992V6.81779H0.634766ZM0.634766 13.7044V16H18.9992V13.7044H0.634766Z"
                      fill={color}
                    />
                  </svg>
                </button>
              </div>

              <div
                className="d-flex w-100 align-items-center no-margin"
                ref={headerDiv}
              >
                <div className="w-100 home-header">
                  <div className="home-header-search-bar-box">
                    <SearchBar popupContainerId="header-search-bar" />
                  </div>

                  <div className="home-header-right-box">
                    {/* <NotificationsIcon className="home-header-notification-icon"/> */}
                    {showLayoutToggle && (
                      <>
                        <div className="layout-toggle-element">
                          <span className="layout-toggle-element-label">
                            Layout: {layoutMode === "new" ? "New" : "Classic"}
                          </span>
                          <LayoutSwitch
                            checked={layoutMode === "new"}
                            onClick={() => {
                              layoutToggle(
                                layoutMode === "new" ? "old" : "new",
                              );
                            }}
                          />
                        </div>
                        <div className="home-header-right-box-spliter" />
                      </>
                    )}
                    {loading ? (
                      <BeautifulSkeleton
                        variant="rectangular"
                        width={180}
                        height={48}
                      />
                    ) : (
                      <div
                        className="home-header-right-box-user"
                        onClick={handleClick}
                      >
                        {!!(userData?.email || user?.email) && user?.email && (
                          <Gravatar
                            email={email}
                            size={40}
                            className="home-header-user-avatar"
                          />
                        )}

                        <div className="header-profile-user-info">
                          <div className="header-profile-user-name">
                            {longestName}
                          </div>
                          <div className="header-profile-user-type">
                            {memberTitle}
                          </div>
                        </div>
                      </div>
                    )}

                    <Menu
                      className="home-header-user-menu"
                      anchorEl={anchorEl}
                      open={openUserMenu}
                      onClose={handleClose}
                    >
                      <MenuItem
                        className={`home-header-user-menu-item ${hardHistory.location.pathname === "/settings/profile" ? "active" : ""}`}
                        onClick={() => goToURL("/settings/profile")}
                      >
                        Edit Profile
                      </MenuItem>
                      <MenuItem
                        className={`home-header-user-menu-item ${hardHistory.location.pathname === "/settings/subscriptions" ? "active" : ""}`}
                        onClick={() => goToURL("/settings/subscriptions")}
                      >
                        My Subscriptions
                      </MenuItem>
                      <MenuItem
                        className={`home-header-user-menu-item ${hardHistory.location.pathname === "/settings/notifications" ? "active" : ""}`}
                        onClick={() => goToURL("/settings/notifications")}
                      >
                        Notifications
                      </MenuItem>
                      <MenuItem
                        className="home-header-user-menu-item"
                        onClick={signOut}
                      >
                        Log Out
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  sidebarOpen: state.sidebarOpen.open,
  themeMode: state.themeMode.themeMode,
  layoutMode: state.layoutMode.layoutMode,
});
const dispatchToProps = (dispatch) => ({
  setSidebar: (open) => dispatch(sidebarSetter(open)),
  layoutToggle: (layoutMode) => dispatch(layoutSetter(layoutMode)),
});
export default connect(stateToProps, dispatchToProps)(HomeHeader);
