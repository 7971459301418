export const SHOW_SNACKBAR = "SHOW_SNACKBAR";

export const showSnackbar = (message, options = {}) => ({
  type: SHOW_SNACKBAR,
  payload: { message, options },
});

const initialState = {
  message: null,
  options: {},
};

export default function snackbarReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        message: action.payload.message,
        options: action.payload.options,
      };
    default:
      return state;
  }
}
